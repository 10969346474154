@font-face {
  font-family: 'robotoregular';
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", "robotoregular", sans-serif;
}

input[type="number"] {
  -webkit-appearance: none;
  /* Safari and Chrome */
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Standard syntax for modern browsers */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
}

.uni-btn {
  background-color: black;
  color: white;
  border-radius: 3px;
  padding: 13px 12px 10px 12px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-size: .9rem;
}

/* FILTER SECTION LIST  */



.filterr .btn-1 {
  position: relative;
}

.btn-1 button {
  border: .1px solid black;
}

.sort-list {
  position: absolute;
  width: 350%;
  background-color: white;
  z-index: 10;
  padding: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin-top: 5px;
}

.sort-list .list-item {
  padding: 10px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sort-list .list-item .outer-c {
  border: .1px solid black;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-list .list-item .inner-c {
  border: .1px solid black;
  border-radius: 50%;
  width: 11px;
  height: 11px;
}


.sort-list .list-item label {
  margin-left: 10px;
  width: 75%;
}


/* modal css */
.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  border-radius: 5px;
  padding: 2rem;
  background-color: white;
  width: 30em;
}

.bg-bl-cw {
  background-color: black !important;
  color: white !important;
}

.btn-submit {
  color: white;
  border: none;
  padding: 8px;
  background-color: black;
  cursor: pointer;
}

.btn-cancel {
  background-color: #e5e3e3;
  color: black;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.close {
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

.modal-contentt {
  margin-bottom: 2rem;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly;
}

/* modal css ends */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content .inputPopup {
  margin: 10px;
  padding: 8px;
  font-size: 1rem;
}

.modal-actions {
  margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.confirm-btn {
  background-color: #f44336;
  /* Red color for confirm */
  color: white;
}

.cancel-btn {
  background-color: #4CAF50;
  /* Green color for cancel */
  color: white;
}


/* short cuts */
.main-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.d-flex {
  display: flex;
}

.ali-itm {
  align-items: center !important;
}

.w-100 {
  width: 100% !important;
}

.m-b2 {
  margin-bottom: 2rem !important;
}

.m-t2 {
  margin-top: 2rem !important;
}

.m-r2 {
  margin-right: 2rem !important;
}

.d-other {
  justify-content: space-between;
  align-items: center;
}

/* url assign list  */
.url-li {
  list-style: none;
}

/* multiple caategory selected */
.parentid {
  position: relative;
}

.multiple-cat {
  max-height: 200px;
  width: 100%;
  background-color: white;
  position: absolute;
  overflow: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  z-index: 2;
}

/* For hiding scrollbar in Webkit browsers (Chrome, Safari, etc.) */
.multiple-cat::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.multiple-cat .options {
  padding: 10px;
}

.multiple-cat .options li {
  padding: 10px;
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.multiple-cat .options li p {
  margin: 0;
  margin-left: 8px;
  font-size: 1rem;
}

/* .multiple-cat .options li span {} */

.selected-itm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ced4da;
}

.catt {
  display: flex;
  flex-wrap: wrap;

}

.selectedItm {
  margin: 3px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;

}

.selectedItm span {
  margin: 2px;
  color: black;
}

.selectedItm span:first-child {
  color: grey;
  cursor: pointer;
}

.selectedItm span:first-child:hover {
  color: black;
}

.btn-AddCat {
  display: flex;
  flex-wrap: nowrap;
}

.btn-AddCat .isdInput {
  width: 30% !important;
  padding: 10px;
  border-right: none !important;
}

.add-form #first-input {
  max-width: 100%;
  min-width: none;

}

/* -----Login-Page------ */
/* src/components/Login.css */
.login {
  display: flex;
  margin: 0;
  font-family: 'Arial', sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafbfe;
}

.login .login-form {
  background-color: transparent;
  width: 100%;
  max-width: 450px;
  padding: 50px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1), 0 0px 14px rgba(0, 0, 0, 0.1);
}

.login h1 {
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.login .subtitle {
  font-size: 14px;
  color: black;
  margin-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.login .input-group {
  margin-bottom: 20px;
}

.login .input-group input {
  width: 100%;
  padding: 12px 15px;
  border: none;

  background-color: transparent;
  color: black;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  box-shadow: 0px 0px rgba(0, 0, 0, 0.1), 0 0px 14px rgba(0, 0, 0, 0.1)
}

.login .input-group input::placeholder {
  color: grey;
}

.date-input {
  display: block;
  width: 100%;
  outline: none;
  /* height: calc(2.0625rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #1b1d1f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 1.1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login .login-button {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: black;
  ;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.login .error-message {
  color: red;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.login .login-button:hover {
  background-color: rgb(67, 66, 66);
}



/* ----Header---- */

.headerr .dropdown-menu {
  position: absolute;
  top: 70px;
  /* Adjust the dropdown position */
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.headerr .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.headerr .dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.headerr .dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.headerr {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid grey;
}

.headerr .icon {
  width: 0%;
}

.main-logo {
  width: 42%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headerr .logo {
  width: 54px;
  height: 54px;
}


.headerr .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.headerr .row .icon h4 {
  font-size: 2rem;
}

.headerr .row img {
  width: 100%;
}

/*-----admin-slider--- */
.active .admin {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid white;
}

.active .admin h1 {
  padding: 13px 0px 13px 0px;
  color: white;
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 590;

}

.active .admin-inactive {
  display: none;
}

.main.active .admin-inactive {
  display: none;
  /* Hide inactive admin when the sidebar is active */
}

.main:not(.active) .admin {
  display: none;
  /* Hide active admin when the sidebar is inactive */
}

.admin-inactive {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.admin-inactive h1 {
  padding: 13px 0px 0px 0px;
  color: white;
  font-size: 1.6rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 590;
}

/* mergeSection */
.merge {
  display: flex;
  width: 100%;
}

.contant {
  width: 100%;
}

/* mergrSection */

.merge {
  display: flex;
  width: 100%;
}

.contant {
  width: 100%;
}

nav {
  position: fixed;
  width: 54px;
  height: 100%;
  background: black;
  transition: .5s;
  overflow: hidden;
}

.main {
  width: 54px;
}

.main .active {
  width: 260px !important;
  transition: .5s;
  overflow: scroll;
}

nav ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  width: 100%;
  position: relative;
}

nav ul li a:hover {
  color: var(--bg-primary);
}

nav ul li:hover a::before {
  background-color: var(--bg-active);
  width: 100%;
}

nav ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--cl-text);
  transition: .2s;
}

nav ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: .2s;
}

nav ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

nav ul li a .title {
  position: relative;
  font-size: 1em;
}

nav ul li a .icon * {
  font-size: 1.2em;
}

nav ul li a.toggle {
  border-bottom: 3px solid var(--bg-primary);
}

.toggle {
  cursor: pointer;
}

header {
  display: block;
}

@media (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    background: black;
    color: var(--cl-text);
  }

  header a {
    color: var(--cl-text)
  }

  nav {
    left: -60px;
  }

  nav.active {
    left: 0;
    width: 100%;
  }

  nav ul li a.toggle {
    display: none;
  }
}

:root {
  --bg-primary: #41444b;
  --bg-secondary: #52575d;
  --bg-active: #fddb3a;
  --cl-text: #f6f4e6;
}

/* ----Common-Class---- */
.tittle {
  text-align: center;
}

.row {
  width: 95%;
  margin: auto;
}

/* Style for the active menu item */
nav ul li.active-menu {
  background-color: #fddb3a;
  /* Active background color */
}

nav ul li.active-menu a {
  color: black;
  /* Text color for active item */
}

/* ---Admin-Category--- */


.category,
.categoryy {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  /* Modern browsers */
  top: 0;
  z-index: 10;
  background-color: white;
}


.sticky-container {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 145px;
  /* Matches the height of .category */
  z-index: 9;
  /* Below .category */
  background-color: white;
  /* Prevent transparency issues */
}

.sticky-container-order {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 145px;
  /* Matches the height of .category */
  z-index: 9;
  /* Below .category */
  background-color: white;
  /* Prevent transparency issues */
}

.category .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.category .category-btn .btn {
  border-radius: 4px;
  font-weight: 300;

  font-family: Arial, Helvetica, ;
  border: none;
  background-color: white !important;
  color: black !important;
  padding: 10px 10px 10px 10px;
}

.category .category-btn .btn svg {
  font-size: 1.3rem;
}

.category .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.8rem;
}

.category .category-nav button {
  border-radius: 4px;
  font-weight: 300;
  font-size: .9rem;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  padding: 10px 10px 10px 10px;
}

.category .category-nav button:hover {
  background-color: #717272;
  color: white;
}

.category .category-filter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding-bottom: 10px;
}

.category .category-filter .filterr {
  display: flex;
  width: 70%;
}

.category .category-filter .filterr .btn-1 button {
  padding: 10px 12px 10px 12px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-size: .9rem;
  font-family: Arial, Helvetica, ;
  border: .1px solid black;
  background-color: black;
  color: white;
}

.category .category-filter .filterr .btn-1 button:hover {
  background-color: #717272;
  color: white;
}

.category-btn {
  display: flex;
  justify-content: end;
  width: 70%;
  align-items: center;
}

.menu-table-container {
  margin: 20px 30px;
  overflow-x: auto;
}

.hideScroll::-webkit-scrollbar {
  display: none;
}

.select-cat .selectList {
  border: 1px solid grey;
  outline: none;
  padding: 7px 9px;
  margin-right: 20px;
  border-radius: 4px;
}

.menu-table-container .drop-select {
  border: 1px solid grey;
  outline: none;
  padding: 6px 6px;
  margin-right: 15px;
  margin-left: 5px;
  border-radius: 4px;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;

}

.search h4 {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: 500;
  font-family: Arial, Helvetica, ;
}

.search input {
  padding: 10px 20px 10px 8px;
  font-size: .9rem;
  border-radius: 4px;
  width: 100%;
  outline: none;
  border: .1px solid gray;
}

.status {
  padding: 5px;
  margin: 0px 4px;
  border-radius: 5px;
  cursor: pointer;
}

button[disabled].activee {
  cursor: not-allowed;
  color: #BBBBBB;
}

button[disabled].inactive {
  cursor: not-allowed;
  color: #BBBBBB;
}

.active-btn {
  background-color: #d4edda;
  color: #155724;
  border: none;
}

.activee {
  background-color: #d4edda;
  color: #155724;
  border: none;
  margin: 0px 4px;
}

.inactive {
  background-color: #f8d7da;
  color: #ff0019;
  border: none;
}

.action-btn {
  border: none;
  background: none;
  cursor: pointer;
  margin: 0px 5px;
  font-size: .9rem;
}

.delete-btn {
  color: red;
}

.revive-btn {
  color: rgb(138, 215, 22);
}

.edit-btn {
  color: #007bff;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
  align-items: center;
}

.pagination-buttons {
  display: flex;
  gap: 10px;
  width: 80%;
  align-items: center;
}

.pagination-buttons button {
  padding: 5px;
  border: 1px solid #ccc;
  background-color: black;
  color: white;
  text-align: center;
  /* cursor: pointer; */
}

.pagination-buttons button:hover {
  background-color: #717272;
}

.category .order-list .order-list-row {
  grid-template-columns: .2fr .4fr .8fr 1fr .5fr .5fr;
}

.banner-list-header {
  grid-template-columns: .2fr .6fr .4fr .66fr 0.4fr .5fr !important;
}

.order-list .order-list-row .img {
  width: 80%;
}

.order-list .order-list-row img {
  height: 100px;
}

.category .order-list .order-list-header {
  grid-template-columns: .2fr .4fr .8fr 1fr .5fr .5fr;
}

.master-modals {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.dynamic-head {
  grid-template-columns: .2fr 1fr 1fr 1fr 1fr !important;
}

.display-cat-list {
  grid-template-columns: .2fr .4fr .8fr 1fr .5fr .5fr !important;
}

.category .order-list {
  margin-top: 15px;
}

.menu-table-container .printer-btn-main {
  display: flex;
  width: 100%;
}

.menu-table-container .printer-btn-main button {
  padding: 8px 13px;
  margin-right: 8px;
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 13px;
  align-items: center;
  width: 50%
}

.menu-table-container .order-list .order-list-row {
  grid-template-columns: .2fr .4fr .8fr .8fr .6fr 1fr .8fr .3fr .5fr .3fr .5fr .8fr;
}

.menu-table-container .order-list .order-list-row .courier-tittle::-webkit-scrollbar {
  height: 6px;
  background-color: transparent;
}

.menu-table-container .order-list .order-list-row .courier-tittle::-webkit-scrollbar-thumb {
  background-color: #103E8A;
  /* The color of the scrollbar when visible */
  border-radius: 4px;
}

/* Show scrollbar when active */
.menu-table-container .order-list .order-list-row .courier-tittle::-webkit-scrollbar-thumb {
  display: block;
}

.menu-table-container .order-list .order-list-header {
  grid-template-columns: .2fr .4fr .8fr .8fr .6fr 1fr .8fr .3fr .5fr .3fr .5fr .8fr;
}

.menu-table-container .order-list {
  margin-top: 30px;
}

.order-list-row .btn {
  display: flex;
  justify-content: space-around;
}

.flex-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.staff-list-header {
  grid-template-columns: .2fr 1fr 1fr 1.7fr 1fr 1fr 1fr !important;
}

/* -------ITEMS-- Add-Items------- */
.add-item {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.add-item .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 20px;
}

.add-item .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.5rem;
}

.add-item .category-btn button {
  border: none;
  background-color: white;
  font-size: 1.2rem;
  color: black;
}

.add-item .add-form {
  margin-top: 30px;
  width: 49%;
  padding: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  /* display: flex; */
  /* justify-content: space-between; */
}

.add-item .add {
  margin-bottom: 15px;
  width: 47%;
}

.add-item .addd {
  /* margin-top: 23px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add .custom-file-input {
  width: 100%;
  border: 1px solid #d7d7d7;
  /* border-radius: 10px; */
  padding: 15px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 200px;
  align-items: center;
  background-color: #f7f7f7;
}

.add .custom-file-input h4 {
  font-weight: 400;
}

.add .custom-file-input:hover {
  background-color: #f7f7f7;
}

.custom-label {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 14px;
}

.custom-label span {
  margin-left: 5px;
}

.addd .input-img img {
  width: 50px;
  margin-bottom: 10px;
}

.add .input-img {
  margin-top: 15px;
  width: 45%;
}

.add .input-img h1 {
  color: #999;
  font-size: 3rem;
}

.add-item .addd .photo {
  width: 45%;
  /* display: flex; */
  /* flex-wrap: nowrap; */
  overflow: auto;
  white-space: nowrap;
}

.add-item .addd .text {
  width: 90%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 15px; */
  font-size: 1.6rem;
}

.add-item .addd .text h1 svg {
  font-size: 2rem;
}

.add-item .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.8rem;
}

.add-item .category-btn button {
  border: none;
  background-color: white;
  font-size: 1.2rem;
  color: black;
}

.jodit-status-bar-link {
  display: none;
}

.add-item .editer {
  width: 100%;
}

.add-item .editer form .addd {
  margin-bottom: 10px;

}

/* Container for image previews with scroll functionality */
.image-preview-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px dashed #BBBBBB;
  margin-top: 10px;
  padding: 15px;
}

/* Individual image container */
.image-preview-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  /* Add space between images */
  border: 1px solid #BBBBBB;
  padding: 10px;
  border-radius: 10px;
  width: 29%;
}

.image-preview-container img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1 / 1;
}

/* Form Group Styling */

.add-item label {
  font-family: Arial, Helvetica;
  font-weight: 500;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 2px;
}

.input-img label {
  width: fit-content;
}

.check-box-avail {
  padding: 14px 3px;
}

.add-item input[type="text"],
.add-item input[type="number"],
.add-item select {
  display: block;
  width: 100%;
  outline: none;
  /* height: calc(2.0625rem + 2px); */
  /* padding: 0.375rem 0.75rem; */
  margin: 0;
  border-radius: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1d1f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 1.1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.css-13cymwt-control {
  border-radius: 0 !important;
  padding: 5px;
}

.category-btn .css-13cymwt-control {
  border-radius: 0 !important;
  padding: 0;
}

.category-btn .css-b62m3t-container,
.category-btn .css-b62m3t-container {
  border-radius: 0 !important;
  padding: 0;
  margin-right: 20px;
  width: 25%;
}

.css-1nmdiq5-menu {
  z-index: 2 !important;
}

.add-item input[type="radio"],
.add-item input[type="checkbox"] {
  margin-right: 8px;
}

/* File Upload */
.add-item input[type="file"] {
  display: block;
  margin-top: 8px;
  padding: 8px 0;
}

.add-item button[type="submit"] {
  display: block;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  /* border-radius: 5px; */
  cursor: pointer;
  /* width: 15%; */
}

.add-item button[type="submit"]:hover {
  background-color: #717272;
}

.aaa {
  display: grid;
  grid-template-columns: .2fr .4fr .8fr 1fr .5fr .5fr;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

/* Time Availability checkbox */
.add-item div label input[type="checkbox"]+label {
  margin-left: 10px;
}

/* Horizontal and Vertical Layout Radio Buttons */

/* Category Icon */
.add-item div input[type="file"] {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 50px;
  height: 180px;
  width: 100%;
  text-align: center;
  color: #999;
}

.add-item div input[type="file"]::before {
  content: "Drag and drop a file here or click";
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.add-item p {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.add-item .editer p {
  font-weight: 400;
  line-height: 1.5;
  color: #1b1d1f;
  font-size: 1.1rem;
  margin-top: 10px;
}

.add-item .editor-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.add-item .toolbar {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
  align-items: center;
}

.add-item .toolbar select,
.toolbar button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  border-radius: 5px;
}

.add-item .toolbar button {
  font-size: 16px;
}

.add-item .toolbar button:hover {
  background-color: #e0e0e0;
}

.add-item .editor {
  border: 1px solid #ccc;
  min-height: 150px;
  padding: 10px;
  margin-top: 10px;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
}

.add-item .editor:focus {
  outline: none;
}

.add-item .jodit-workplace {
  height: 150px !important;
}

/* .add-item .btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
} */
.action-btnnn {
  border: none;
  background: none;
  cursor: pointer;
  color: #007bff;
  margin: 0px 5px;
  font-size: .9rem;
  padding: 4px;
  border-radius: 5px;
  background-color: #e2e2ff;

}

.flex-btns button {
  width: 72%;
}

.add-item .btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.submit-btn {
  width: 49% !important;
}

.add-field {
  display: flex;
  justify-content: flex-end;

}

.add-field button {
  font-size: 1rem;
  border-radius: 0 !important;
  background-color: black;
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.submit-btnnn {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
}

.submit-btnnn button {
  font-size: 1rem;
  border-radius: 0 !important;
  background-color: black;
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.submit-btn button {
  font-size: 1rem;
  border-radius: 0 !important;
  background-color: black;
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.add-item .add-mrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
}

.add-item .add-mrp .add-mrp-1 {
  width: 40%;
}

.menu-table-container .order-list .order-list-row .btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* ---------ORDER--------- */

.order {
  display: flex;
  justify-content: center;
}

.order .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.order .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.5rem;
}

.order .category-nav button {
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  padding: 10px 10px 10px 10px;
}

.order .category-nav button:hover {
  background-color: #717272;
  color: white;
}

.order .category-filter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.order .category-filter .filterr {
  display: flex;
  width: 70%;
}

.order .category-filter .filterr .btn-1 button {
  padding: 10px 12px 10px 12px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
}

.order .category-filter .filterr .btn-1 button:hover {
  background-color: #717272;
  color: white;
}


.order-list-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 15px;

}

.order-list-header,
.order-list-row {
  display: grid;
  grid-template-columns: .2fr .7fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr !important;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.orders-menu,
.orders-menu-header {
  display: grid;
  grid-template-columns: .7fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.orders-menu-header {
  font-weight: bold;
  border-radius: 5px 5px 0px 0px;
  /* box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3) ; */
  /* text-align: center; */
}

.order-list-header {
  font-weight: bold;
  border-radius: 5px 5px 0px 0px;
  /* box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.3) ; */
  /* text-align: center; */
}

.category-list-header {
  font-weight: bold;
  border-radius: 5px 5px 0px 0px;
  display: grid;
  grid-template-columns: .7fr, 1fr, 1fr, 1fr, 1fr, 1fr, 1fr;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.order-list-row {
  background-color: #fff;
  transition: background-color 0.2s;
  font-size: .9rem;
  /* text-align: center; */
}

.order-list-row:hover {
  background-color: #f1f1f1;
}

.order .status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
}

.order .paid {
  background-color: #d4edda;
  color: #155724;
}

.order .unpaid {
  background-color: #f8d7da;
  color: #721c24;

}

.order .fulfilled {
  background-color: #d1ecf1;
  color: #0c5460;
}

.order .unfulfilled {
  background-color: #fff3cd;
  color: #856404;
}

/* Dashboard bar chart css */
.deshbord {
  display: flex;
  justify-content: center;
}

.barChart-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.deshbord-text {
  margin-top: 25px;
  margin-bottom: 30px;
}

.deshbord .text-a h1 {
  font-family: Arial, Helvetica;
  margin: 4px 0px;
  font-weight: 500;
}

.deshbord .text-b p {
  font-family: Arial, Helvetica;
  font-weight: 500;
  color: gray;
  margin-bottom: 6px;
  color: rgb(147, 146, 146);
}

.Inner-container {
  /* border: .1px solid #BBBBBB; */
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
  padding: 20px;
}

.BarChart {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  margin: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: end;
  height: 95%;
  padding: 0px 20px;
}

.BarChart .Chart {
  background-color: #6b46c1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.BarChart .Chart:hover {
  background-color: #8563d4
}

.BarChart .Chart span {
  color: white;
}

.month-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 95%;
  padding: 0px 20px;
}

.month-container .month {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.deshbord {
  font-family: Arial, sans-serif;
}

.deshbord .pieChart-Container {
  margin-top: 50px;
  margin-bottom: 40px;
}

.deshbord .pieChart {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
}

.deshbord .legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.deshbord .legend {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.deshbord .legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}

/* General styles */
.dashboard-container {
  padding: 24px;
  font-family: Arial, sans-serif;
  min-height: 100vh;
}

.dashboard-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 16px;
}

.card {
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
}

.total-sales-card {
  background-color: #6b46c1;
  color: white;
}

.card h2 {
  font-size: 18px;
  margin-bottom: 8px;
}

.value {
  font-size: 32px;
  font-weight: bold;
}

.value-small {
  font-size: 24px;
}

.subtext {
  font-size: 12px;
  opacity: 0.8;
}

.growth {
  font-size: 14px;
  color: green;
  margin-top: 4px;
}

.divider {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin: 12px 0;
}

.chart-line svg polyline {
  stroke-linecap: round;
}

.chart-bar svg rect {
  fill: #8884d8;
}


/* PAGINATION */
.page-main-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-main-container .input-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22%;
}

.container-p {
  /* margin: 0 auto; */
  display: flex;
  flex-direction: column
}

.paginate {
  display: flex;
  justify-content: space-between;
}

.paginate-details {
  margin-top: 16px;
  font-weight: 500;
  text-align: center;
}

button {
  background-color: transparent;
  border: none;
}

.nextBtn {
  margin-left: 20px;
}



.prevBtn,
.nextBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: #2a3b52
}

.nextBtn-icon,
.prevBtn-icon {
  display: flex;
}

.prevBtn {
  margin-right: 20px;
}

.prevBtn svg {
  margin-right: 6px;
}

.nextBtn svg {
  margin-left: 6px;
}

.prevBtn svg,
.nextBtn svg {
  color: #2a3b52
}

button[disabled].prevBtn,
button[disabled].nextBtn {
  cursor: not-allowed;
  /* color: rgb(131 124 124 1 / var(--tw-text-opacity)); */
  color: #BBBBBB;
  background-color: black;
}

.containerBtns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.leftContainer {
  display: flex;
  max-width: 190px;
  justify-content: end;
}

.rightContainer {
  display: flex;
  max-width: 190px;
  justify-content: start;
}

.activeBtn {
  margin: 0 12px;
  padding: 4px 6px;
  min-width: 40px;
  /* height: 40px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  font-size: 20px;
  color: black !important;
  border: 4px solid #c9c6ff
}

.numberBtn {
  cursor: pointer;
  margin: 0 4px;
  min-width: 40px;
  padding: 4px;
  font-size: 18px;
}

.numberBtn[disabled] {
  cursor: not-allowed
}


/* cms list */
/* Basic container styling */
.cms-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
.cms-container h2 {
  margin-bottom: 16px;
  font-size: 1.5rem;
  color: #333;
}

/* List styling */
.cms-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Each list item styling */
.cms-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ddd;
}

/* Title styling */
.cms-title {
  font-size: 1rem;
  color: #555;
}

/* Icon styling */
.edit-icon {
  font-size: 1.2rem;
  color: #555;
  cursor: pointer;
  transition: color 0.3s ease;
}

.edit-icon:hover {
  color: #007bff;
}

/* ----Cms---- */
.cms-editer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cms-editer .row {
  width: 93%;
}

.cms-editer .row .editer {
  width: 49%;
  /* margin-top: 20px; */
}

.ck-editor__editable_inline {
  height: 250px;
  width: auto;
}

.cms-editer .ck-editor__editable_inline {
  height: 380px;
  width: auto;
}

.cms-editer .row .editer .ck.ck-powered-by {
  display: none;
}

.cms-editer .row .submit-btn {
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.ck.ck-balloon-panel.ck-powered-by-balloon .ck.ck-powered-by a {
  display: none !important;
}




/* -----setting------ */
/* Container for the whole page */
.main-container-setting .settings-page {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f8f9fa;
}

/* Grid layout for the settings cards */
.main-container-setting .settings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(378px, 1fr));
  gap: 20px;
  /* max-width: 1000px; */
  width: 100%;
}

/* Styling for each settings card */
.main-container-setting .settings-card {
  background-color: #ffffff;
  padding: 20px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.main-container-setting .settings-card-a {
  height: 850px;
}

.main-container-setting .settings-card h3 {
  margin-bottom: 16px;
  font-size: 1.2rem;
  color: #333;
}

/* Toggle item styling */
.main-container-setting .toggle-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Switch (toggle button) styling */
.main-container-setting .switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.main-container-setting .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.main-container-setting .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.main-container-setting .slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.main-container-setting input:checked+.slider {
  background-color: #4CAF50;
}

.main-container-setting input:checked+.slider:before {
  transform: translateX(14px);
}

/* Input and select styling */
.main-container-setting .settings-card input,
.settings-card select {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

/* Button styling */
.main-container-setting .submit-button {
  width: 100%;
  padding: 10px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.main-container-setting .submit-button:hover {
  background-color: rgb(60, 59, 59);
}

/* Responsive styling */
@media (max-width: 600px) {
  .cms-container {
    width: 100%;
    padding: 15px;
  }

  .cms-item {
    padding: 10px;
  }

  .cms-title {
    font-size: 0.9rem;
  }

  .edit-icon {
    font-size: 1rem;
  }
}




/* -------Cart-detail------- */

/* ShoppingCart.css */


.cart-detail {
  margin: 10px;
  color: black;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;
  margin-top: 40px;
}

.cart-detail .cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cart-detail .cart-header h2 {
  margin: 0;
  font-size: 1.5em;
}

.cart-detail .cart-header p {
  margin: 0;
  font-size: 0.9em;
  color: black;
}

.cart-detail .sort-button {
  background: none;
  border: none;
  color: black;
  font-size: 1.1rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cart-detail .cart-items {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-top: 18px;
}

.cart-detail .cart-item {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 5px -1px;
  border-radius: 8px;
  padding: 10px;
}

.cart-detail .item-image {
  width: 65px;
  height: 70px;
  border-radius: 5px;
  margin-right: 10px;
}

.cart-detail .item-details {
  flex: 2;
}

.cart-detail .item-details h4 {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.cart-detail .item-details p {
  margin: 0;
  font-size: 0.85em;
  color: #ccc;
}

.cart-detail .item-quantity,
.item-price {
  width: 100px;
  font-size: 1.2rem;
  text-align: center;
}

.cart-detail .delete-button {
  background: none;
  border: none;
  color: #ff5c5c;
  cursor: pointer;
  font-size: 1.2em;
}


.cart-detail .sort-container {
  position: relative;
  display: inline-block;
  width: 15%;
}

.cart-detail .sort-menu {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  z-index: 10;
  width: 100%;
}

.cart-detail .sort-menu li {
  padding: 10px 20px;
  color: black;
  cursor: pointer;
  font-size: 1rem;
  border-bottom: 1px solid rgb(210, 210, 210);
}

.cart-detail .sort-menu li:hover {
  background-color: rgb(230, 229, 229);
}

.cart-detail .sort-menu .activeee {
  background-color: rgb(230, 229, 229);
}

/* -----order-detail------ */
.order-detail .container {
  flex-wrap: wrap;
  display: flex;
  gap: 20px;
  padding: 20px;
}

.order-detail .order-detail-flex {
  display: flex;
  padding: 20px;
}

.order-detail .card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 49%;
}

.order-detail .card-title {
  font-size: 16px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.order-detail .row {
  margin: 10px 0;
  background-color: rgb(239, 238, 238);
  padding: 10px;
  width: 100%;
  align-items: center;
}

.order-detail .label {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}

.order-detail .value {
  font-size: 16px;
  color: #555;
}

.order-detail .table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.order-detail .table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-detail .table th {
  background-color: #f2f2f2;
}

.order-detail .card-b {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
  overflow: hidden;
}

.order-detail .card-title {
  font-size: 20px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.order-detail .item-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  overflow-x: auto;
}

.order-detail .item-table th,
.item-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.order-detail .item-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.order-detail .info-icon {
  font-size: 12px;
  color: #888;
  margin-left: 5px;
}

.order-detail .summary {
  margin-top: 20px;
}

.order-detail .summary-row {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-top: 1px solid #ddd;

}

.order-detail .summary-row span {
  font-size: 16px;
}

.order-detail .summary-row:first-child {
  border-top: none;
}

.order-detail .summary-row .negative {
  color: red;
}

.order-detail .summary-row.total {
  font-weight: bold;
  border-top: 2px solid #000;
  padding-top: 10px;
  font-size: 18px;
}



.order-detail .card-order {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 100%;
}

.order-detail .orderr,
.ordered-item-container {
  padding: 20px;
}

.order .printer-btn-main button {
  padding: 10px 20px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  font-size: 1rem;
}

/* kjhgfduifuighhjhvbjvbjkhvvjk */
/* General styles */
.modal-printer .packing-slip {
  font-family: Arial, sans-serif;
  color: #333;
  padding: 5px 10px;
  background: white;
  width: 800px;
  margin: 0 auto;
  page-break-after: always;
  break-inside: avoid;
}

.main-printer {
  /* position: -webkit-sticky; */
  position: fixed;
  top: 0;
  right: 0;
  /* bottom: 0; */
  display: flex;
  justify-content: end;
  z-index: 11;
}

.printer-button h1 {
  background-color: white;
  padding: 5px 10px;
  font-size: 1.3rem;
  border-radius: 5px;
}

.printer-button {
  padding-top: 15px;
  position: -webkit-sticky;
  margin: 15px;
  /* Safari */
  position: sticky;
  top: 0;
  left: 0;
  /* right: 45px; */
  bottom: 0;
  display: flex;
  justify-content: end;
  z-index: 1002;
  /* height: 100vh; */
}

.modal-printer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  height: 100vh;
}

.modal-printer .flex {
  height: -webkit-fill-available;
}

.packing-slip .summary-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px grey solid;
}

.center-invoice-title {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin: 20px 0; */
  position: relative;
}

.center-invoice-title::before,
.center-invoice-title::after {
  content: "";
  flex: 1;
  height: 1px;
  border-top: 1px dashed #333;
  /* Dashed line */
  margin: 0 10px;
}


/* Header */
.modal-printer .packing-slip-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #000;
  /* padding-bottom: 10px; */
}

.modal-printer .packing-slip-title {
  font-size: 24px;
  font-weight: bold;
}

.modal-printer .packing-slip-order-details {
  text-align: right;
}

/* Address Section */
.modal-printer .packing-slip-address {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.modal-printer .packing-slip-ship-to,
.packing-slip-bill-to {
  width: 45%;
}

.modal-printer h3 {
  font-size: 18px;
  margin-bottom: 5px;
}

.modal-printer .bill {
  display: flex;
  justify-content: center;
}

/* Items Table */
/* .modal-printer .packing-slip-items {
  margin-top: 20px;
} */

.modal-printer table {
  width: 100%;
  border-collapse: collapse;
}

.modal-printer th,
td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}

.modal-printer .packing-slip-item-details {
  display: flex;
  align-items: center;
}

.modal-printer .packing-slip-item-image {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

/* Footer */
.modal-printer .packing-slip-footer {
  text-align: center;
  /* margin-top: 30px; */
  font-size: 14px;
}

/* .modal-printer .page-break {
  page-break-after: always;
}

.modal-printer .page-break:last-child {
  page-break-after: auto;
} */

.printingContainer * {
  visibility: visible;
  /* position: absolute; */
  /* width: 100%; */
  padding: 5px;
  top: 0;
  left: 0;
}

@media print {
  body * {
    visibility: hidden;
  }

  .modal-printer .page-break {
    page-break-after: always;
  }

  .modal-printer .page-break:last-child {
    page-break-after: auto;
  }

  .packing-slip {
    font-family: Arial, sans-serif;
    color: #333;
    padding: 20px;
    background: white;
    width: 800px;
    margin: 0 auto;
    page-break-after: always;
  }

  .packing-slip:last-child {
    page-break-after: auto;
  }

  .printingContainer {
    visibility: visible;
    /* position: absolute; */
    width: 100%;
    padding: 10px;
    top: 0;
    left: 0;
  }
}

/* publisher */

.publisher-header {
  display: grid;
  padding: 10px;
  align-items: center;
  font-weight: bold;
  grid-template-columns: .8fr .8fr 1fr 1fr;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #ddd;
}

.order-list-publicer {
  display: grid;
  align-items: center;
  padding: 10px;
  grid-template-columns: .8fr .8fr 1fr 1fr !important;
  border-bottom: 1px solid #ddd;
}

.order-list-publicer .btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}

.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  background-color: #f5f5f5;
}

/* Spinner styling */
.loading-container .spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}


/* RESPONSIVE DESIGN */
@media screen and (max-width: 600px) {
  .main-logo {
    width: 80%;
  }

  .main {
    width: 0;
  }

  .contant {
    width: auto;
  }

  .deshbord {
    min-width: 1000px;
    overflow-x: auto;
  }

  .menu-table-container {
    overflow-x: auto;
    min-width: 1000px;
    margin: 0;
  }

  .category .category-filter {
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .category .category-filter .filterr {
    width: 100%;
  }

  .category .category-filter .search {
    width: 70%;
    margin-top: 1rem;
  }

  .category-btn .css-b62m3t-container {
    width: 50%;
  }

  .add .input-img {
    width: 100%;
  }

  .add-item .add-form {
    width: 100%;
  }

  .pagination-buttons {
    width: 100%;
  }

  .pagination {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .page-main-container {
    flex-wrap: wrap;
    gap: 10px;
  }

  .page-main-container .input-page {
    width: 100%;
  }

  .container-p {
    justify-content: center;
    width: 100%;
  }

  .order-detail .card-b {
    min-width: 500px;
  }


  .order-list-header,
  .order-list-row {
    /* grid-template-columns: repeat(5, 1fr); */
    min-width: 1000px;
  }

  .order-detail .order-detail-flex {
    flex-wrap: wrap;
  }

  .order-detail .card {
    width: 100%;
  }

  .cms-editer .row .editer {
    width: 100%;
  }
}

/* excel design */
.report .excel-container {
  width: 90%;
  max-width: 1200px;
  overflow-x: auto;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.report table {
  width: 100%;
  border-collapse: collapse;
  min-width: 1000px;
}

.report thead {
  background-color: #4CAF50;
  color: white;
}

.report th,
.report td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.report tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.report tbody tr:hover {
  background-color: #ddd;
}

.report .download-btn {
  margin-top: 10px;
  padding: 8px 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report .download-btn:hover {
  background: #0056b3;
}