* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body * {
  -webkit-font-smoothing: antialiased;
}

button {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: 'Roboto', sans-serif;
}


/* FILTER SECTION LIST  */
.filter .btn-1 {
  position: relative;
}

.sort-list {
  position: absolute;
  width: 250%;
  background-color: white;
  z-index: 10;
  padding: 10px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  margin-top: 5px;
}



/* Modal Overlay */
.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.sort-list .list-item {
  padding: 10px 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sort-list .list-item .outer-c {
  border: .1px solid black;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sort-list .list-item .inner-c {
  border: .1px solid black;
  border-radius: 50%;
  width: 11px;
  height: 11px;
}


.sort-list .list-item label {
  margin-left: 10px;
}


/* modal css */
.modal-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  border-radius: 5px;
  padding: 2rem;
  background-color: white;
  width: 30em;
}

.bg-bl-cw {
  background-color: black !important;
  color: white !important;
}

.btn-submit {
  color: white;
  border: none;
  padding: 8px;
  background-color: black;
  cursor: pointer;
}

.btn-cancel {
  background-color: #e5e3e3;
  color: black;
  border: none;
  padding: 8px;
  cursor: pointer;
}

.close {
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
}

.modal-contentt {
  margin-bottom: 2rem;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly;
}

/* modal css ends */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-actions {
  margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.confirm-btn {
  background-color: #f44336;
  color: white;
}

.cancel-btn {
  background-color: #4CAF50;
  color: white;
}


/* short cuts */
.main-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.d-flex {
  display: flex;
}

.w-100 {
  width: 100% !important;
}

.m-b2 {
  margin-bottom: 2rem !important;
}

.d-other {
  justify-content: space-between;
  align-items: center;
}


/* multiple caategory selected */
.parentid {
  position: relative;
}

.multiple-cat {
  max-height: 200px;
  width: 100%;
  background-color: white;
  position: absolute;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.multiple-cat .options {
  padding: 10px;
}

.multiple-cat .options li {
  padding: 10px;
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.multiple-cat .options li p {
  margin: 0;
  margin-left: 8px;
  font-size: 1rem;
}

.selected-itm {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #ced4da;
}

.catt {
  display: flex;
  flex-wrap: wrap;
}

.selectedItm {
  margin: 3px;
  padding: 4px;
  display: flex;
  justify-content: space-between;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
}

.selectedItm span {
  margin: 2px;
  color: black;
}

.selectedItm span:first-child {
  color: grey;
  cursor: pointer;
}

.selectedItm span:first-child:hover {
  color: black;
}

.btn-AddCat {
  display: flex;
  flex-wrap: nowrap;
}

.btn-AddCat button {
  border: none;
  background-color: black;
  color: white;
  padding: 5px 7px;
}

.add-form #first-input {
  max-width: 100%;
  min-width: none;

}

/* -----Login-Page------ */
.login {
  display: flex;
  margin: 0;
  font-family: 'Arial', sans-serif;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fafbfe;
}

.login .login-form {
  background-color: transparent;
  width: 100%;
  max-width: 450px;
  padding: 50px;
  text-align: center;
  color: white;
  border: none;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1), 0 0px 14px rgba(0, 0, 0, 0.1);
}

.login h1 {
  font-size: 2rem;
  color: black;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.login .subtitle {
  font-size: 14px;
  color: black;
  margin-bottom: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.login .input-group {
  margin-bottom: 20px;
}

.login .input-group input {
  width: 100%;
  padding: 12px 15px;
  border: none;
  background-color: transparent;
  color: black;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-decoration: none;
  outline: none;
  box-shadow: 0px 0px rgba(0, 0, 0, 0.1), 0 0px 14px rgba(0, 0, 0, 0.1)
}

.login .input-group input::placeholder {
  color: grey;
}

.login .login-button {
  width: 100%;
  padding: 12px;
  border: none;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 30px;
  cursor: pointer;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.login .error-message {
  color: red;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 1rem;
}

.login .login-button:hover {
  background-color: rgb(67, 66, 66);
}

/* ----Header---- */

.header .dropdown-menu {
  position: absolute;
  top: 70px;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.header .dropdown-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.header .dropdown-menu li {
  padding: 10px 15px;
  cursor: pointer;
  white-space: nowrap;
}

.header .dropdown-menu li:hover {
  background-color: #f1f1f1;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 15px gray;
  border: none !important;
}

.header .icon {
  width: 0%;
}

.header .logo {
  width: 4%;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.header .row .icon h4 {
  font-size: 2rem;
}

.header .row img {
  width: 100%;
}

/*-----admin-slider--- */
.active .admin {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  border-bottom: 3px solid white;
}

.active .admin h1 {
  padding: 13px 0px 13px 0px;
  color: white;
  font-size: 2rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 590;
}

.active .admin-inactive {
  display: none;
}

.main.active .admin-inactive {
  display: none;
}

.main:not(.active) .admin {
  display: none;
}

.admin-inactive {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.admin-inactive h1 {
  padding: 13px 0px 0px 0px;
  color: white;
  font-size: 1.6rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: 590;
}

/* mergeSection */
.merge {
  display: flex;
  width: 100%;
}

.contant {
  width: 100%;
}

/* mergrSection */

.merge {
  display: flex;
  width: 100%;
}

.contant {
  width: 100%;
}

nav {
  position: fixed;
  width: 54px;
  height: 100%;
  background: black;
  transition: .5s;
  overflow: hidden;
}


.active {
  width: 260px !important;
  transition: .5s;
  overflow: hidden;
}

nav ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
  width: 100%;
  position: relative;
}

nav ul li a:hover {
  color: var(--bg-primary);
}

nav ul li:hover a::before {
  background-color: var(--bg-active);
  width: 100%;
}

nav ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--cl-text);
  transition: .2s;
}

nav ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: .2s;
}

nav ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}

nav ul li a .title {
  position: relative;
  font-size: 1em;
}

nav ul li a .icon * {
  font-size: 1.2em;
}

nav ul li a.toggle {
  border-bottom: 3px solid var(--bg-primary);
}

.toggle {
  cursor: pointer;
}

header {
  display: block;
}

@media (max-width: 768px) {
  header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    background: black;
    color: var(--cl-text);
  }

  header a {
    color: var(--cl-text)
  }

  nav {
    left: -60px;
  }

  nav.active {
    left: 0;
    width: 100%;
  }

  nav ul li a.toggle {
    display: none;
  }
}

:root {
  --bg-primary: #41444b;
  --bg-secondary: #52575d;
  --bg-active: #fddb3a;
  --cl-text: #f6f4e6;
}

/* ----Common-Class---- */
.tittle {
  text-align: center;
}

.row {
  width: 95%;
}

/* Style for the active menu item */
nav ul li.active-menu {
  background-color: #fddb3a;
}

nav ul li.active-menu a {
  color: black;
}

/* ---Admin-Category--- */


.category {
  display: flex;
  justify-content: center;
}

.category .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.category .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.8rem;
}

.category .category-nav button {
  border-radius: 4px;
  font-weight: 300;
  font-size: .9rem;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  padding: 10px 10px 10px 10px;
}

.category .category-nav button:hover {
  background-color: #717272;
  color: white;
}

.category .category-filter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.category .category-filter .filter {
  display: flex;
  width: 70%;
}

.category .category-filter .filter .btn-1 button {
  padding: 10px 12px 10px 12px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-size: .9rem;
  font-family: Arial, Helvetica, ;
  border: .1px solid black;
  background-color: black;
  color: white;
}

.category .category-filter .filter .btn-1 button:hover {
  background-color: #717272;
  color: white;
}

.category-btn {
  display: flex;
  justify-content: end;
  width: 50%;
  align-items: center;
}

.menu-table-container {
  margin: 30px;
}

.select-cat .selectList {
  border: 1px solid grey;
  outline: none;
  padding: 7px 9px;
  margin-right: 20px;
  border-radius: 4px;
}

.menu-table-container .drop-select {
  border: 1px solid grey;
  outline: none;
  padding: 6px 6px;
  margin-right: 15px;
  margin-left: 5px;
  border-radius: 4px;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 30%;
}

.search h4 {
  margin-bottom: 0;
  margin-right: 5px;
  font-weight: 500;
  font-family: Arial, Helvetica, ;
}

.search input {
  padding: 10px 20px 10px 8px;
  font-size: .9rem;
  border-radius: 4px;
  width: 100%;
  outline: none;
  border: .1px solid gray;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
}

.active-btn {
  background-color: #d4edda;
  color: #155724;
  border: none;
}

.activee {
  background-color: #d4edda;
  color: #155724;
  border: none;
}

.inactive {
  background-color: #f8d7da;
  color: #721c24;
  border: none;
}

.action-btn {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 1.2rem;
}

.delete-btn {
  color: red;
}

.revive-btn {
  color: rgb(138, 215, 22);
}

.edit-btn {
  color: #007bff;
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 40px;
}

.pagination-buttons {
  display: flex;
  gap: 10px;
}

.pagination-buttons button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: black;
  color: white;
  cursor: pointer;
}

.pagination-buttons button:hover {
  background-color: #717272;
}

.category .order-list .order-list-row {
  grid-template-columns: .4fr 1.5fr 1fr .8fr .7fr;
}

.order-list .order-list-row .img {
  width: 90%;
}

.order-list .order-list-row img {
  height: 84px;
}

.category .order-list .order-list-header {
  grid-template-columns: .4fr 1.5fr 1fr .8fr .7fr;
}

.category .order-list {
  margin-top: 30px;
}

.menu-table-container .order-list .order-list-row {
  grid-template-columns: .6fr 1.4fr 1fr 1fr 1fr 1fr 1fr;
}

.menu-table-container .order-list .order-list-header {
  grid-template-columns: .6fr 1.4fr 1fr 1fr 1fr 1fr 1fr;
}

.menu-table-container .order-list {
  margin-top: 30px;
}

.order-list-row .btn {
  display: flex;
  justify-content: space-around;
}

/* -------ITEMS-- Add-Items------- */
.add-item {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}

.add-item .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 20px;
}

.add-item .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.5rem;
}

.add-item .category-btn button {
  border: none;
  background-color: white;
  font-size: 1.2rem;
  color: black;
}

.add-item .add-form {
  margin-top: 30px;
  width: 50%;
  padding: 20px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.add-item .add {
  margin-bottom: 15px;
  width: 47%;
}

.add-item .addd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add .custom-file-input {
  width: 100%;
  border: 1px solid #d7d7d7;
  padding: 15px 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 200px;
  align-items: center;
  background-color: #f7f7f7;
}

.add .custom-file-input h4 {
  font-weight: 400;
}

.add .custom-file-input:hover {
  background-color: #f7f7f7;
}

.custom-label {
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 14px;
}

.custom-label span {
  margin-left: 5px;
}

.addd .input-img img {
  width: 50px;
  margin-bottom: 10px;
}

.add .input-img {
  margin-top: 15px;
  width: 45%;
}

.add .input-img h1 {
  color: #999;
  font-size: 3rem;
}

.add-item .addd .photo {
  width: 45%;
  overflow: auto;
  white-space: nowrap;
}

.add-item .addd .text {
  width: 90%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-item .addd .text h1 svg {
  font-size: 2rem;
}

.add-item .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.8rem;
}

.add-item .category-btn button {
  border: none;
  background-color: white;
  font-size: 1.2rem;
  color: black;
}

.jodit-status-bar-link {
  display: none;
}

.add-item .editer {
  width: 100%;
}

.add-item .editer form .addd {
  margin-bottom: 10px;
}

.image-preview-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  border: 1px dashed #BBBBBB;
  margin-top: 10px;
  padding: 15px;
}

/* Individual image container */
.image-preview-container {
  position: relative;
  display: inline-block;
  margin: 10px;
  border: 1px solid #BBBBBB;
  padding: 10px;
  border-radius: 10px;
  width: 29%;
}

/* .image-preview-container img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1/1;
} */


/* Form Group Styling */

.add-item label {
  font-family: Arial, Helvetica;
  font-weight: 500;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 2px;
}

.check-box-avail {
  padding: 14px 3px;
}

.add-item input[type="text"],
.add-item select {
  display: block;
  width: 100%;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  color: #1b1d1f;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  padding: 10px 15px;
  font-size: 1.1rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.add-item input[type="radio"],
.add-item input[type="checkbox"] {
  margin-right: 8px;
}

/* File Upload */
.add-item input[type="file"] {
  display: block;
  margin-top: 8px;
  padding: 8px 0;
}

.add-item button[type="submit"] {
  display: block;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

.add-item button[type="submit"]:hover {
  background-color: #717272;
}

/* Time Availability checkbox */
.add-item div label input[type="checkbox"]+label {
  margin-left: 10px;
}

/* Horizontal and Vertical Layout Radio Buttons */

/* Category Icon */
.add-item div input[type="file"] {
  border: 2px dashed #ccc;
  border-radius: 6px;
  padding: 50px;
  height: 180px;
  width: 100%;
  text-align: center;
  color: #999;
}

.add-item div input[type="file"]::before {
  content: "Drag and drop a file here or click";
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.add-item p {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.add-item .editor-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.add-item .toolbar {
  display: flex;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  border-radius: 5px;
  align-items: center;
}

.add-item .toolbar select,
.toolbar button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background: white;
  cursor: pointer;
  border-radius: 5px;
}

.add-item .toolbar button {
  font-size: 16px;
}

.add-item .toolbar button:hover {
  background-color: #e0e0e0;
}

.add-item .editor {
  border: 1px solid #ccc;
  min-height: 150px;
  padding: 10px;
  margin-top: 10px;
  font-family: sans-serif;
  background-color: white;
  border-radius: 5px;
}

.add-item .editor:focus {
  outline: none;
}

.add-item .jodit-workplace {
  height: 150px !important;
}

.add-item .btn {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.add-item .btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.submit-btn {
  width: 50% !important;
}

.submit-btn button {
  font-size: 1rem;
  border-radius: 0 !important;
  background-color: black;
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
}

.add-item .add-mrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
}

.add-item .add-mrp .add-mrp-1 {
  width: 40%;
}

.menu-table-container .order-list .order-list-row .btn {
  display: flex;
  justify-content: space-around;
}

/* ---------ORDER--------- */

.order {
  display: flex;
  justify-content: center;
}

.order .category-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 20px;
}

.order .category-nav h4 {
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  font-size: 1.5rem;
}

.order .category-nav button {
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
  padding: 10px 10px 10px 10px;
}

.order .category-nav button:hover {
  background-color: #717272;
  color: white;
}

.order .category-filter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

.order .category-filter .filter {
  display: flex;
  width: 70%;
}

.order .category-filter .filter .btn-1 button {
  padding: 10px 12px 10px 12px;
  margin-right: 15px;
  border-radius: 4px;
  font-weight: 300;
  font-family: Arial, Helvetica, ;
  border: none;
  background-color: black;
  color: white;
}

.order .category-filter .filter .btn-1 button:hover {
  background-color: #717272;
  color: white;
}


.order-list-container {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 30px;

}

.order-list-header,
.order-list-row {
  display: grid;
  grid-template-columns: .2fr 1fr 1.3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid #ddd;
}


.order-list-header {
  font-weight: bold;
  border-radius: 5px 5px 0px 0px;
}

.order-list-row {
  background-color: #fff;
  transition: background-color 0.2s;
  font-size: .9rem;
}

.order-list-row:hover {
  background-color: #f1f1f1;
}

.order .status {
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  text-align: center;
}

.order .paid {
  background-color: #d4edda;
  color: #155724;
}

.order .unpaid {
  background-color: #f8d7da;
  color: #721c24;

}

.order .fulfilled {
  background-color: #d1ecf1;
  color: #0c5460;
}

.order .unfulfilled {
  background-color: #fff3cd;
  color: #856404;
}

/* Dashboard bar chart css */
.deshbord {
  display: flex;
  justify-content: center;
}

.barChart-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.deshbord-text {
  margin-top: 25px;
  margin-bottom: 30px;
}

.deshbord .text-a h1 {
  font-family: Arial, Helvetica;
  margin: 4px 0px;
  font-weight: 500;
}

.deshbord .text-b p {
  font-family: Arial, Helvetica;
  font-weight: 500;
  color: gray;
  margin-bottom: 6px;
  color: rgb(147, 146, 146);
}

.Inner-container {
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 500px;
  padding: 20px;
}

.BarChart {
  border-left: 1px solid black;
  border-bottom: 1px solid black;
  margin: 10px;
  width: 95%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: end;
  height: 95%;
  padding: 0px 20px;
}

.BarChart .Chart {
  background-color: black;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.BarChart .Chart:hover {
  background-color: #717272
}

.BarChart .Chart span {
  color: white;
}

.month-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  width: 95%;
  padding: 0px 20px;
}

.month-container .month {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.deshbord {
  font-family: Arial, sans-serif;
}

.deshbord .pieChart-Container {
  margin-top: 50px;
  margin-bottom: 40px;
}

.deshbord .pieChart {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
}

.deshbord .legend-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.deshbord .legend {
  display: flex;
  align-items: center;
  margin: 5px 10px;
}

.deshbord .legend-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
}





/* -----USER INTERFACE------- */


.home-row {
  width: 98%;
  display: flex;
  justify-content: end;
}

/* -----Navbar------ */
/* Global Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  background-color: #f0fdfd;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

/* Top Bar */
.navbar .header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  background-color: #f0fdfd;
  color: black;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.navbar .header-top p {
  font-weight: 400;
  font-size: 0.99rem;
}

.navbar .header-top a {
  color: black;
  text-decoration: none;
  margin-left: 1rem;
}

.navbar .header-top a:hover {
  color: blue;
  /* Change text color to blue on hover */
  text-decoration: underline;
}

.navbar .header-top .p {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
  color: rgb(232, 33, 33);
  width: 56%;
}

.navbar .header-links {
  display: flex;
  align-items: center;
}

.navbar .account {
  position: relative;
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.navbar .user {
  margin-right: 20px;
}

.navbar .cart {
  margin-right: 20px;
}

.navbar .cart-button {
  background-color: #103E8A;
  color: white;
  border: none;
  border-radius: 8px;
  height: 60px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  overflow: hidden;
}

.navbar .cart-icon {
  font-size: 1.3rem;
  margin-right: 4px;
}

.navbar .cart-info {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.navbar .cart-info .number {
  font-size: 19px;
}


.navbar .cart-info .price {
  font-size: 19px;
}

.navbar .cart-button:hover {
  background-color: #15489c;
}

.navbar .cart-info span {
  line-height: 1;
}

.navbar .rotate svg {
  font-size: 1.3rem;
}

.navbar .dropdown-menu {
  position: absolute;
  top: 13%;
  right: 17.3%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1;
  width: 11%;
  padding: 10px;
}

.navbar .dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar .dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.navbar .dropdown-menu li:hover {
  background-color: #f1f1f1;
}

/* Main Header */
.navbar .header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 2rem;
  width: 100%;
}

.res-navbar {
  display: none;
}

.res-navbar .logo {
  -webkit-tap-highlight-color: transparent;
}

.res-navbar .header-links {
  -webkit-tap-highlight-color: transparent;
}

.navbar .logo {
  width: 5%;
}

.navbar .logo img {
  width: 100%;
  height: auto;
}

.res-nav-b .search-bar {
  display: none;
}

.res-nav-b .search-bar .search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 10;
  max-height: 30vh;
  overflow-y: auto;
  border-radius: 5px;
}

.res-nav-b .search-bar .search-dropdown ul {
  list-style: none;
  padding: 0px 10px;
  margin: 0;
}

.res-nav-b .search-bar .search-dropdown ul a {
  text-decoration: none;
  color: black;
}

.res-nav-b .search-bar .search-dropdown li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.navbar .search-bar .search-dropdown li:hover {
  background-color: #f0f0f0;
}

.navbar .search-bar {
  display: flex;
  width: 50%;
  position: relative;
  align-items: center;
}

.navbar .search-bar .input {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding: 0px 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: white;
  display: flex;
  align-items: center;
}

.navbar .search-bar .input input {
  width: 100%;
  padding: 0.75rem;
  border-radius: 10px;
  border: none;
  outline: none;
  background: none;
  font-size: 1rem;
}

.navbar .search-bar .search-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ddd;
  z-index: 10;
  max-height: 30vh;
  overflow-y: auto;
  border-radius: 5px;
}

.navbar .search-bar .search-dropdown ul {
  list-style: none;
  padding: 0px 10px;
  margin: 0;
}

.navbar .search-bar .search-dropdown ul a {
  text-decoration: none;
  color: black;
}

.navbar .search-bar .search-dropdown li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar .search-bar .search-dropdown li:hover {
  background-color: #f0f0f0;
}


.navbar .search-bar .input svg {
  font-size: 1.5rem;
}

.navbar .search-bar button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 1rem;
}

.navbar .header-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar .header-links a {
  text-decoration: none;
  color: black;
}


.navbar .header-links .dropdown-menu {
  position: absolute;
  top: 50px;
  width: 54%;
  left: -12px;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
  animation: dropdownFadeIn 0.3s ease;
  border-radius: 4px;
}

.navbar .header-links .dropdown-menu ul li:hover {
  background-color: #E3FFFD;
  color: #000;
}

.navbar .header-links .dropdown-menu ul li {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  font-size: 16px;
  color: #333;
}

.navbar .header-links .dropdown-menu ul li svg {
  margin-right: 7px;
  font-size: 20px;
}

.navbar .header-links {
  width: 25%;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: auto;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  justify-content: space-around;
}

.navbar .header-links i {
  font-size: 1.5rem;
}

.navbar .header-links span {
  margin: 2px;
  font-size: 1.1rem;
}

.navbar .cart {
  position: relative;
}

.navbar .cart i {
  font-size: 1.8rem;
}

/* ----Filter-Category----- */
/* Dropdown menu container */

.filter-category .dropdown-menu-1 {
  position: absolute;
  top: 108px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
  animation: dropdownFadeIn 0.3s ease;
  border-radius: 4px;
}

.filter-category .dropdown-menu-2 {
  position: absolute;
  top: 100px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
  animation: dropdownFadeIn 0.3s ease;
  border-radius: 4px;
}

.filter-category .dropdown-menu-3 {
  position: absolute;
  top: 100px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
  animation: dropdownFadeIn 0.3s ease;
  border-radius: 4px;
}

.filter-category .dropdown-menu-4 {
  position: absolute;
  top: 100px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 10px 0;
  animation: dropdownFadeIn 0.3s ease;
  border-radius: 4px;
}

/* Unordered list inside dropdown */
.filter-category .dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-category .dropdown-menu ul a {
  text-decoration: none;
  color: #333;
}

/* Individual list items */
.filter-category .dropdown-menu ul li {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: left;
  font-size: 14px;
  color: #333;
}

/* Hover effect for dropdown items */
.filter-category .dropdown-menu ul li:hover {
  background-color: #E3FFFD;
  color: #000;
}

/* Border between dropdown items */
.filter-category .dropdown-menu ul li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

/* Animation for dropdown fade-in */
@keyframes dropdownFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Global Styles */
.filter-category {
  display: flex;
  justify-content: center;

}

.filter-category .filter::-webkit-scrollbar {
  height: 6px;
  background-color: transparent;
  /* Initially transparent */
}

.filter-category .filter::-webkit-scrollbar-thumb {
  background-color: #103E8A;
  /* The color of the scrollbar when visible */
  border-radius: 4px;
}

/* Show scrollbar when active */
.filter-category .filter.scroll-active::-webkit-scrollbar-thumb {
  display: block;
}

.filter-category .filter {
  display: flex;
  align-items: start;
  background-color: white;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  margin-bottom: 5px;
  max-height: 350px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  flex-direction: column;
}

.filter-category .filter::-webkit-scrollbar {
  width: 5px;
}

.filter-category .filter .swiper-wrapper {
  display: flex;
  flex-wrap: wrap !important;
  flex-direction: column;
}

.filter-category .filter .swiper-horizontal {
  touch-action: pan-y;
  -webkit-touch-callout: none;
  height: 160px;
  display: none;
}


.filter-category .filter .nav-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  width: 14.50%;
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;
}

.filter-category .filter .nav-item a {
  text-decoration: none;
  color: black;
}

.filter-category .filter .a {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  text-align: center;
  width: 14.28%;
  text-decoration: none;
  color: black;
}

.filter-category .filter .nav-item .filter-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  height: 130px;
}

.filter-category .filter .nav-item .filter-link {
  -webkit-tap-highlight-color: transparent;
}

.filter-category .filter .nav-item .img {
  margin-bottom: 8px;
}

.filter-category .filter .nav-item .text-container {
  text-align: center;
}


.filter-category .filter .a .img-1 {
  margin-top: 14px;
}

.filter-category .filter .a .img {
  width: 35%;
}

.filter-category .filter .a img {
  width: 100%;
  height: auto;
}

.filter-category .filter .a span {
  font-size: 1rem;
  width: 100%;
  margin: 3px;
}

.filter-category .filter .nav-item .img-1 {
  margin-top: 14px;
}

.filter-category .filter .nav-item .img {
  width: 35%;
  aspect-ratio: 2/2;
  margin-bottom: 5px;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.filter-category .filter .nav-item .img:hover {
  transform: scale(1.1);
}

.filter-category .filter .nav-item img {
  width: 100%;
  height: 100%;
  aspect-ratio: 2/2;
}

.filter-category .filter .nav-item .text-container {
  font-size: 1rem;
  width: 100%;
  margin: 3px;
  overflow: hidden;
  display: -webkit-box;
  display: box;
  /* Standard property */
  -webkit-box-orient: vertical;
  box-orient: vertical;
  /* Standard property */
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.filter-category .filter .rotate {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.filter-category .filter .dropdown-content {
  display: none;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  position: absolute;
  z-index: 10;
}

.filter-category .filter .nav-item .span:hover .dropdown-content {
  display: block;
}

.filter-category .filter .nav-item .dropdown-content {
  display: block;
}


/* ----Slider----- */
.swiper {
  width: 100%;
  height: 366px;
}

.swiper-slide .image {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.featuredbrand {
  position: relative;
}

.display-none-2 {
  display: none;
}

.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
  left: 0px;
  background-color: blue;
  color: black;
}

.swiper-button-prev.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
  left: 0px;
  background-color: blue;
  color: black;
}

.slick-dots {
  display: none !important;
}

.slider-container {
  position: relative;
}

.slick-slider {
  position: relative;
}

.slick-prev,
.slick-next {
  font-size: 25px;
  color: black;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 56%;
  padding: 10px;
  cursor: pointer;
  z-index: 2;
  padding-bottom: 4px;
  box-shadow: 0px 0px 6px -3px;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.slick-prev {
  left: 5px;
  color: black;
}

.slick-next {
  right: 5px;
  color: black;
}

.slider-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.3s;
}

/* ------Featured-Brand------ */

.featured-row {
  width: 100%;
  box-shadow: 0px -3px 3px 0 rgba(0, 0, 0, 0.15);
}

.featuredbrand {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
}

.featuredbrand .slider-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featuredbrand .text {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredbrand .text h2 {
  font-size: 2rem;
  margin-top: 5px;
  font-family: Arial, sans-serif;
}

.featuredbrand .book {
  width: 90% !important;
  height: 365px;
  padding: 10px !important;
  overflow: hidden !important;
  margin-left: 15px;
  border: none;
  box-sizing: border-box;
  border: 1px solid #ddd;
  margin: 15px;
  border-radius: 10px;
  border: 0.5px solid rgb(232, 232, 232);
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  border-radius: 8px;
}

/* Discount Badge */
.discount-badge {
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #007bff;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 8px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Discount Badge Text */
.discount-badge p {
  margin: 0;
  line-height: 1.2;
}


.discount-badge-book-sub {
  position: absolute;
  top: 10px;
  left: 0;
  background-color: #007bff;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 5px 8px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Discount Badge Text */
.discount-badge-book-sub p {
  margin: 0;
  line-height: 1.2;
}

.featuredbrand .book-img {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  position: relative;
}

.featuredbrand .book-img a {
  width: 90%;
  display: flex;
  justify-content: center;
  position: relative;
}

.featuredbrand .book-img .p {
  position: absolute;
  z-index: 100;
  width: 100%;
  padding: 10px;
  bottom: 0px;
  text-align: center;
  background-color: white;
  color: black;
  opacity: 0.8;
  height: 236px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.featuredbrand .book-img img {
  width: 100%;
  height: 235px;
  position: relative;
  transition: transform 0.3s ease;
}

.featuredbrand .book-img img:hover {
  transform: scale(1.07);
  /* Move the element 5px upwards */
}

.featuredbrand .book-img .book-text {
  width: 47%;
}


.featuredbrand .book .shipping {
  font-family: Arial, sans-serif;
  font-size: 18px;
  font-weight: 400;
  height: 43px;
  color: black;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 10px;
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.featuredbrand .book .book-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0px 10px;
}

.featuredbrand .book .book-btn strike {
  margin-left: 5px;
}

.featuredbrand .book .book-btn p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featuredbrand .book .book-btn .original-price {
  color: gray;
  font-family: Arial, sans-serif;
  font-size: 17px;
}

.featuredbrand .book .book-btn .price {
  display: flex;
  font-size: 17px;
}

.featuredbrand .book .book-btn .price-b {
  font-family: Arial, sans-serif;
  font-size: 18px;
}

.featuredbrand .book .book-btn button {
  padding: 4px 0px;
  width: 70px;
  height: 30px;
  border-radius: 5px;
  background-color: #103E8A;
  color: white;
  font-size: .9rem;
  cursor: pointer;
}

.featuredbrand .book .book-btn .add-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0px;
  width: 70px;
  height: 30px;
  color: white;
  border-radius: 5px;
  background-color: #103E8A;
}

.featuredbrand .book .book-btn .add-btn span {
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
  height: 23px;
}

.featuredbrand .book .book-btn .p {
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
}

/* -----Footer------ */

.footer-container {
  background-color: #003366;
  color: white;
  padding: 30px 0px 0px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.15);
}

.footer-container .footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 95%;
  margin: auto;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.footer-container .footer-column-abc {
  display: flex;
  align-items: center;
  width: 100%;
}

.footer-container .footer-logo img {
  width: 100%;
  height: 165px;
}

.footer-container .footer-social-media {
  margin-top: 4px;
}

.footer-container .footer-social-media a {
  margin: 0px 7px;
  display: inline-block;
  padding: 5px 8px;
  font-size: 1.5rem;
  color: white;
  width: 12%;
}

.footer-container .footer-logo {
  width: 50%;
}

.footer-container .footer-social-media img {
  width: 100%;
  height: auto;
}

.footer-container .footer-links {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 15px;
}

.footer-container .footer-column {
  width: 25%;
}

.footer-container .footer-column a {
  text-decoration: none;
}

.footer-container .footer-column hr {
  width: 57%;
}

.footer-container .footer-column h3 {
  color: white;
  margin-bottom: 10px;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: Arial, sans-serif;
}

.footer-container .footer-column ul {
  list-style-type: none;
  padding: 0;
}

.footer-container .footer-column ul li span {
  margin-right: 8px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.footer-container .footer-column ul li:hover span {
  transform: translateX(5px);
}

.footer-container .footer-column ul li {
  -webkit-tap-highlight-color: transparent;
}

.footer-container .footer-column ul li {
  color: white;
  margin-bottom: 13px;
  font-weight: 300;
  font-family: Arial, sans-serif;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.footer-container .footer-column ul li:hover {
  transform: translateX(5px);
  color: white;
  cursor: pointer;
}

.footer-container .footer-column .footer-p {
  font-size: 1.1rem;
  margin-bottom: 5px;
  margin-top: 15px;
  font-weight: bold;
  color: white;
  border-bottom: 1px solid rgb(238, 238, 238);
}


.footer-container .footer-column .adress {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
}

.footer-container .footer-column .adress strong {
  color: white;
}

.footer-container .footer-column .adress h3 {
  color: white;
  font-size: 1.3rem;

}

.footer-container .footer-column ul li a:hover {
  text-decoration: underline;
}

.footer-container .footer-payment-methods {
  margin-top: 10px;
  text-align: center;
  margin-bottom: 3px;
}

.footer-container .footer-payment-methods img {
  margin: 0 10px;
  width: 50px;
  height: auto;
}

.footer-column p {
  margin: 5px 0;
  color: white
}

.footer-column p a {
  text-decoration: none;
  color: white;
  outline: none;
}

.footer-column p a:hover {
  color: blue;
  /* Change text color to blue on hover */
  text-decoration: underline;
}

.footer-column span {
  margin-right: 5px;
}

.footer-container .copy {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-color: #003366;
  padding: 10px 0px;
  border-top: 1px solid rgb(238, 238, 238);
}

.footer-container .copy p {
  color: white;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.footer-container .fixed-whatsapp {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25d366;
  color: white;
  font-size: 2rem;
  border-radius: 50%;
  padding: 5px 6px 0px 6px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.footer-container .fixed-whatsapp:hover {
  transform: scale(1.1);
}


/* ------Footer-AboutUs------ */


/* Modal Box */
.modal-contenttt {
  background: white;
  /* padding: 20px; */
  border-radius: 8px;
  width: 70%;
  /* max-width: 1000px; */
  max-height: 95vh;  /* Max height set for scroll */
  overflow-y: auto;  /* Enable vertical scroll */
  position: relative;
}

/* Close Button */
.modal-closee {
  position: absolute;
  top: 10px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Scrollbar Styling */
.modal-contenttt::-webkit-scrollbar {
  width: 6px;
}

.modal-contenttt::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.modal-contenttt::-webkit-scrollbar-thumb:hover {
  background: #555;
}


/* AboutUs.css */
.aboutus-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  max-width: 100%;
  margin: 0 auto;
}

.aboutus-container .hero-section {
  text-align: center;
  background-color: #003366;
  color: white;
  padding: 15px 20px;
}

.aboutus-container .hero-section h1 {
  font-size: 2.2rem;
  margin-bottom: 10px;
}

/* AboutUs.css */
.company-details {
  padding: 20px;
}

.company-details h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #333;
}

.company-details .footer-text {
  font-size: 1rem;
  line-height: 1.8;
  color: #555;
}

.company-details ol {
  padding-left: 20px;
  /* Adds left padding to the list */
  margin: 20px 0;
}

.company-details ol li {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 10px;
  position: relative;
  color: #333;
}

.company-details .footer-text p {
  margin: 10px 0;
}

@media (max-width: 768px) {
  .company-details {
    padding: 15px;
    margin: 10px auto;
  }

  .company-details h2 {
    font-size: 1.5rem;
  }

  .company-details .footer-text {
    font-size: 0.9rem;
  }
}


/* -------Books-Category-Collection----- */

/* ---Collage-Books */

.animated-text {
  font-size: 2rem;
  color: #666;
  animation: pulse 1.5s ease-in-out infinite;
  text-align: center;

}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.white-screen .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.white-screen .btn button {
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #103E8A;
  color: white;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.white-screen {
  margin-top: 20%;
  position: relative;
  top: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}


.springboard-notes {
  display: flex;
  justify-content: center;
}

.active-aa {
  width: 260px !important;
  transition: .5s;
  overflow: hidden;
}

.springboard-notes .home-row {
  display: flex;
  justify-content: space-between;
  height: calc(-130px + 100vh);
}

.springboard-notes .home-row-b {
  display: flex;
  justify-content: space-between;
  height: 100%;
}

.springboard-notes .left-book {
  width: 20%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.springboard-notes .left-book a {
  text-decoration: none;
  color: black;
}

.springboard-notes .left-book .flex {
  width: 100%;
}

.springboard-notes .left-book .flex .flex-2 .text {
  width: 100%;
}

.springboard-notes .left-book .flex-2 {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  border-right: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  border-left: 1px solid rgb(238, 238, 238);
}

.springboard-notes .left-book .flex:hover {
  background-color: #f0fdfd
}

.springboard-notes .left-book .flex .img {
  border-radius: 14%;
  height: 65px;
  width: 30%;
  overflow: hidden;
}

.springboard-notes .left-book .flex .img img {
  width: 100%;
  height: 100%;
}

.springboard-notes .left-book .flex h3 {
  margin-left: 10px;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
}

.springboard-notes .books-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  background: rgb(244, 246, 251);
  padding: 10px 0px 10px 10px;
}

.springboard-notes .main-sort {
  flex-wrap: wrap;
  width: 100%;
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.main-sort .text-book {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background-color: white;
  padding: 10px 30px 10px 30px;
  border-right: 1px solid rgb(238, 238, 238);
  border-bottom: 1px solid rgb(238, 238, 238);
  border-left: 1px solid rgb(238, 238, 238);
}

.main-sort .text-book .price-select {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
}

.main-sort .text-book .price-select span {
  margin-right: 10px;
}

.main-sort .text-book .price-select select {
  margin-bottom: 0;
  width: 35%;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 4px;
  font-size: .9rem;
  outline: none;
}

.main-sort .text-book h2 {
  -webkit-tap-highlight-color: transparent;
}

.main-sort .text-book h2 {
  color: black;
  font-family: Arial, sans-serif;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  font-size: 21px;
  padding: 5px 0 4px 0;
  margin: 0;
}

.sort-2 .text-book {
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 65px;
  background-color: white;
  padding: 10px 30px 10px 30px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}

.sort-2 .text-book .price-select {
  display: flex;
  justify-content: end;
  align-items: center;
  width: 50%;
}

.sort-2 .text-book .price-select span {
  margin-right: 10px;
}

.sort-2.text-book .price-select select {
  margin-bottom: 0;
  width: 35%;
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 4px;
  font-size: .9rem;
}

.sort-2 .text-book h2 {
  font-size: 1.6rem;
  color: black;
  font-family: Arial, sans-serif;
}

.springboard-notes .book-card {
  width: 19.3%;
  height: 318px;
  background: rgb(255, 255, 255);
  border: 0.5px solid rgb(232, 232, 232);
  box-shadow: rgba(0, 0, 0, 0.04) 2px 2px 8px;
  border-radius: 8px;
}

.springboard-notes .flex.active-aa {
  background-color: #f0fdfd;
  border-right: 4px solid blue;
  width: 100% !important;
}

.springboard-notes .book-card .h3 {
  height: 60px !important;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.springboard-notes .book-card-res {
  height: 336px !important;
}

.springboard-notes .book-card h3 {
  font-family: Arial, sans-serif;
  font-size: 17px;
  height: 42px;
  padding: 0px 10px;
  font-weight: 500;
  color: black;
  margin-top: 15px;
  margin-bottom: 10px;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.springboard-notes .book-card .p {
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
}

.springboard-notes .book-image {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
  position: relative;
}


.springboard-notes .book-image .p {
  position: absolute;
  z-index: 100;
  width: 100%;
  padding: 10px;
  bottom: 0px;
  text-align: center;
  background-color: white;
  color: black;
  opacity: 0.8;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.springboard-notes .book-image a {
  width: 90%;
  display: flex;
  height: 200px;
  justify-content: center;
  position: relative;
}

.springboard-notes .book-image img {
  width: 100%;
  transition: transform 0.3s ease;
}

.springboard-notes .book-image img:hover {
  transform: scale(1.03);
}

.springboard-notes .price-section {
  display: flex;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
}

.springboard-notes .price {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.springboard-notes .price .mrp-price {
  font-family: Arial, sans-serif;
  font-size: 16px;
  margin-right: 5px;
}

.springboard-notes .original-price {
  color: gray;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.springboard-notes .original-price .price {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

.springboard-notes .price-section button {
  padding: 4px 0px;
  width: 70px;
  height: 28px;
  border-radius: 5px;
  background-color: #103E8A;
  color: white;
  font-size: .9rem;
  cursor: pointer;
}

.springboard-notes .price-section .add-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px 0px;
  width: 70px;
  height: 28px;
  color: white;
  border-radius: 5px;
  background-color: #103E8A;
}

.springboard-notes .price-section .add-btn span {
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
  height: 23px;
}

.springboard-notes .price-section .add-btn .pp {
  font-size: 1.3rem;
  cursor: pointer;
  color: white;
}

.main-menu {
  display: none;
}

.popup-overlay {
  display: none;
}

/* ----Cart----- */

.cart-container {
  width: 30%;
  /* height: 100vh; */
  background-color: #f0f4fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  transition: transform 0.4s ease-in-out;
  z-index: 1003;
  transform: translateY(100%);
  overflow-y: auto;
}

.cart-container .cart-header h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.cart-container .cart-header {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  background-color: white;
  padding: 10px 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: end;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.cart-container .cart-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-container .cart-items {
  margin-bottom: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 10px 10px;
  width: 100%;
  border-radius: 9px;
}

.cart-item-add-more {
  background-color: white;
  margin: 8px;
  border-radius: 9px;
}

.cart-item-add-more .sec-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 15px 10px;
  margin-bottom: 5px;
}


.cart-item-add-more .sec-btn h4 {
  font-size: 1rem;
  color: black;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}


.cart-item-add-more .sec-btn button {
  border-radius: 5px;
  background-color: #103E8A;
  padding: 10px;
  color: white;

  font-size: .9rem;
  cursor: pointer;
}

.cart-item-add-more .sec-btn button:hover {
  background-color: #15489c;
}

.cart-item .cart-item-info {
  display: flex;
  width: 100%;
}

.cart-item .cart-item-image {
  width: 21%;
  margin-right: 10px;
  border-radius: 5px;
}

.cart-item .cart-item-image img {
  height: 85px;
  width: 100%;
  border-radius: 5px;
}

.cart-item .cart-item-details {
  display: flex;
  flex-direction: column;
  width: 70%;
}

.cart-item .cart-item-details .cart-item-price {
  display: flex;
  margin-top: 5px;
}

.cart-item .cart-item-name {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 1.1rem;
  font-weight: 600;
  overflow: hidden;
  display: -webkit-box;
  display: box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
}

.cart-item .cart-item-description {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
  width: 95%;
}


.cart-item .discounted-price {
  font-size: 17px;
  font-weight: 500;
  margin-right: 7px;
}

.cart-item .original-price {
  font-size: 17px;
  color: #999;
  text-decoration: line-through;
}

.cart-item .quantity-control {
  width: auto;
}

.cart-item .quantity-control-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0px;
  height: 30px;
  width: 65px;
  color: white;
  border-radius: 5px;
  background-color: #103E8A;
  margin-bottom: 10px;
}

.cart-item .quantity-control span {
  font-size: 1.1rem;
  cursor: pointer;
  color: white;
  display: flex;
  align-items: center;
}

.cart-item .btn {
  width: 100%;
}

.cart-item .btn button {
  width: 100%;
  cursor: pointer;
}

.cart-item .btn button svg {
  font-size: 1.4rem;
}

.promo-code {
  -webkit-tap-highlight-color: transparent;
}

.promo-code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  background-color: white;
  padding: 15px 5px;
  border-radius: 6px;
  margin: 8px;
}

.promo-code .promo-left {
  display: flex;
  align-items: center;
}

.promo-code .promo-icon {
  color: #00c853;
  font-size: 30px;
  margin-right: 8px;
}

.promo-code .promo-details {
  display: flex;
  flex-direction: column;
}

.promo-code .promo-status {
  font-size: 15px;
  color: #103E8A;
}

.promo-code .promo-message {
  font-size: 16px;
  font-weight: bold;
  color: #000;
}

.promo-code .promo-arrow {
  color: black;
  font-weight: bold;
  font-size: 18px;
}

.bill-details {
  background-color: white;
  border-bottom: 1px solid #ddd;
  margin: 8px 8px 0px 8px;
  padding: 10px 10px 0px;
  border-radius: 8px 8px 0px 0px;
}

.delivery-speed {
  background-color: white;
  margin: 8px 8px 8px 8px;
  padding: 10px 10px 10px;
  border-radius: 8px 8px 8px 8px;
}

.delivery-speed label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: black;
  cursor: pointer;
}

.delivery-speed input[type="radio"] {
  margin-right: 10px;
  width: 15px;
  height: 15px;
  accent-color: #103e8a;
  /* चेकबॉक्स का रंग */
}

.bill-details .gift {
  margin-right: 12px;
  font-size: 1.4rem;
}

.bill-details .bill-title {
  font-weight: bold;
  margin-bottom: 10px;
}

.bill-details .bill-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.bill-details .bill-row span {
  display: flex;
  align-items: center;
}

.total-amountt {
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
  padding: 5px 0px 0px 0px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1)
}

.total-amount {
  text-align: center;
  bottom: 0;
  background-color: white;
  z-index: 10;
  min-height: 0px;
  padding: 0px 8px 7px 8px;
}

.total-row {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  background-color: white;
  margin: 0px 8px 8px 8px;
  padding: 10px 10px 15px;
  border-radius: 0px 0px 8px 8px;
}

.total-amount .proceed-button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border: none;
  width: 100%;
  padding: 18px 10px;
  background-color: #103E8A;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.total-amount .proceed-button:hover {
  background-color: #15489c;
}

.total-amount .proceed-button .total-amo-text2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.total-amount .proceed-button-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  padding: 18px 10px;
  background-color: #103E8A;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.total-amount .proceed-button-flex:hover {
  background-color: #15489c;
}

.total-amount .proceed-button-flex .total-amo-text2-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.total-amount .proceed-button-flex .total-amo-text2-flex p {
  margin-right: 5px;
}

.total-amount .proceed-button .total-amo-text2 .arrow {
  font-weight: bold;
}

.total-amount .proceed-button .total-amo-text2 p {
  margin-right: 4px;
  font-weight: 400;
}

.cart-visible {
  transform: translateY(0);
}

.cart-hidden {
  transform: translateY(1000px);
}

/* Overlay for dimming background */
.overlay {
  position: fixed;
  top: 0;
  left: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1007;
}

/* Login Modal Styles */
.login-modal-number {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1007;
}

.overlay-login {
  position: fixed;
  top: 0;
  left: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1003;
}

.overlay-cart {
  position: fixed;
  top: 0;
  left: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1002;
}

.login-modal-number .login-modal-header span {
  cursor: pointer;
}

.login-modal-header-2 {
  display: none;
}

.login-modal-number .login-modal-header {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-bottom: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
}

.login-modal-number .login-modal-body {
  padding: 4px 0 15px;
}

.login-modal-number .img-1 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 8px 8px 0px 0px;
}

.login-modal-number .img {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 0px 0px;
}

.login-modal-number .img img {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
}

.login-modal-number .login-modal-body .phone-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: center;
  margin-top: 10px;
}

.login-modal-number .login-modal-body .country-flag {
  width: 35px;
  height: 25px;
  margin-right: 8px;
}

.login-modal-number .login-input {
  width: 60%;
  padding: 11px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 15px;
  outline: none;
}

.login-modal-number .login-input:focus {
  border: 1px solid #ccc;
  background-color: white;
}

.login-modal-number .text-cart {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.login-modal-number .text-cart .em {
  width: 20%;
}

.login-modal-number .text-cart h3 {
  font-weight: 400;
  font-size: 19px;
}

.login-modal-number .login-button {
  width: 72%;
  padding: 10px 10px;
  background-color: #2957A4;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-modal-number .btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-modal-number .login-button:hover {
  background-color: #0056b3;
}

.login-modal-number .login-footer {
  text-align: center;
  margin-top: 10px;
}

.login-modal-number .login-footer a {
  color: #007bff;
  text-decoration: none;
  font-size: 14px;
}

.login-modal-number .login-footer a:hover {
  text-decoration: underline;
}

/* Otp-cart */

.login-modal {
  top: 50%;
  left: 50%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 1004;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.login-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.otp-section {
  margin-top: 20px;
}

.otp-inputs {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.otp-inputs input {
  padding-left: 10px;
}

.otp-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-button {
  background-color: #103E8A;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  width: 50%;
  cursor: pointer;
}

.login-button:hover {
  background-color: #15489c;
}

/* ----Otp----- */
.otp-modal .otp-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.otp-modal .otp-modal-header h2 {
  font-weight: 400;
}

.otp-modal .text {
  margin-top: 10px;
  margin-bottom: 15px;
}

.otp-modal .text .p {
  color: rgb(54, 53, 53);
}

.otp-modal .otp-a {
  color: #103E8A;
}

.otp-modal .otp-b {
  color: #103E8A;
  margin-top: 14px;
  margin-bottom: 14px;
}

.otp-modal .otp {
  margin-top: 14px;
  margin-bottom: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-modal .error-message {
  color: red;
}

.otp-modal .otp strong {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 15px;
  margin-right: 3px;
}

.otp-modal .otp p {
  color: gray;
  font-size: 15px;
}

.otp-modal .otp-inputs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 16px;
}

.otp-modal .otp-input-box {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.2s;
}

.otp-modal .otp-input-box:focus {
  border-color: #007bff;
  box-shadow: 0 0 2px rgba(0, 123, 255, 0.5);
}


/* -----empty-cart----- */
.empty-cart-container {
  text-align: center;
  font-family: Arial, sans-serif;
  align-items: center;
  display: flex;
  justify-content: center;
}

.empty-cart-container .cart-image {
  position: relative;
  display: inline-block;
  margin-bottom: 1rem;
}

.empty-cart-container .cart-image img {
  mix-blend-mode: darken;
}

.empty-cart-container .empty-cart-container .icon-cart {
  width: 100px;
  height: 100px;
  stroke: #6c757d;
}

.empty-cart-container .cart-icon-animation {
  position: absolute;
  top: -15px;
  right: -20px;
  animation: float 2s infinite;
}

.empty-cart-container .icon-item {
  width: 20px;
  height: 20px;
  stroke: #6c757d;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.empty-cart-container h3 {
  color: #343a40;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.empty-cart-container p {
  color: #6c757d;
  margin-bottom: 1rem;
}

.empty-cart-container .start-shopping-btn {
  background-color: #103E8A;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 4px;
}

.empty-cart-container .start-shopping-btn:hover {
  background-color: #15489c;
}

/* -------NewAdressForm----- */
.new-address-form {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100vh;
  background-color: #f7fbff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 1007;
  height: 100%;
}

.new-address-form .form-text {
  overflow-y: auto;
  max-height: 750px;
}

.new-address-form .text {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  font-weight: 500;
  padding-bottom: 10px;
  background-color: white;
  padding: 10px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  border-bottom: 1px solid #ddd;
  z-index: 1000;
}

.new-address-form .text h2 {
  font-size: 1.6rem;
  font-weight: 500;
}

.new-address-form a {
  text-decoration: none;
}

.new-address-form .btn {
  display: flex;
  align-items: center;
  padding: 10px 10px 0px 10px;
  width: 100%;
  z-index: 1000;
  top: 30px;
  background-color: #f7fbff;
  margin-top: 10px;
}

.new-address-form .btn h3 span {
  margin: 4px;
  margin-top: 2px;
}

.new-address-form .btn h3 {
  -webkit-tap-highlight-color: transparent;
}

.new-address-form .btn h3 {
  display: flex;
  padding: 10px;
  background-color: white;
  color: #003366;
  border-radius: 8px;
  border: none;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
}


/* ----Adress-Form---- */

/* Modal for Address Form */
.address-modal {
  display: flex;
  justify-content: space-between;
  background-color: #f1f4fa;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1008;

}

/* Modal Header */
.address-modal .address-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background: white;
  margin-bottom: 5px;
  border-radius: 8px;
}

.address-modal .address-modal-header h3 {
  font-size: 20px;
  font-weight: 400;
}

.address-modal .close-modal {
  font-size: 24px;
  cursor: pointer;
}

/* Map Section */
.address-modal .map-section {
  margin-top: 13px;
  width: 49%;
}

.address-modal .map-section .p {
  margin-top: 10px;
  font-weight: 100;
}

.address-modal .current-location-btn {
  display: block;
  width: 100%;
  background-color: #2957A4;
  color: white;
  padding: 10px;
  text-align: center;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}

.address-modal .delivery-location {
  margin-top: 15px;
  background-color: #f7fbff;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  box-shadow: 0px 0px 1px 0px;
  align-items: center;
}

.delivery-location button {
  border-radius: 5px;
  background-color: #103E8A;
  padding: 10px;
  color: white;
  font-size: .9rem;
  cursor: pointer;
}

.address-modal .delivery-location .location h3 {
  font-size: 2rem;
}

.address-modal .location-details .p {
  color: gray;
}

.address-modal .location-details p {
  margin: 4px;
  font-size: 17px;
}

/* Address Form */
.address-modal .address-form {
  margin-top: 8px;
  width: 100%;
}

.address-modal .address-type-selection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 10px;
}

.address-modal .address-type-btn {
  padding: 10px 15px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  flex-grow: 1;
  text-align: center;
  margin-right: 10px;
}

.address-modal .address-type-btn.active {
  background-color: #2957A4;
  color: white;
  border: none;
}

.address-modal .address-details input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.address-modal .address-details p {
  margin-top: 3px;
  margin-bottom: 6px;
  color: gray;
}

.address-modal .save-address-btn {
  width: 100%;
  background-color: #2957A4;
  color: white;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.address-detail-form .error-message {
  color: red;
}

/* ----Select-Delivery-Adress */
.new-address-form .form-lite {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  border: none;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
}

.new-address-form .form-btn {
  display: flex;
  justify-content: end;
  align-items: center;
}

.new-address-form .form-btn button {
  padding: 7px 14px;
  background-color: #103E8A;
  color: white;
  border-radius: 5px;
}

.new-address-form .form-lite .form-a {
  display: flex;
  align-items: center;
  width: 100%;
}

.new-address-form .form-lite .icon {
  color: #003366;
}

.new-address-form .inner-text h3 {
  font-weight: 500;
}

.new-address-form .inner-text p {
  color: grey;
  font-size: .8rem;
  margin-top: 2px;
}

.new-address-form .form {
  padding: 10px;
  width: 100%;
}

.new-address-form .form-lite .icon span {
  font-size: 1.5rem;
  margin-right: 10px;
}

/* ----Cartiteam-Add----- */
.cartitem {
  position: fixed;
  top: 0%;
  right: 0%;
  width: 55%;
  z-index: 1004;
  height: 100vh;
  background-color: #f7fbff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.cartitem .cart-items {
  margin-bottom: 50px;
  overflow-y: auto;
}

.cartitem .cart-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: white;
  padding: 10px;
}

.cartitem .head h2 {
  font-size: 1.7rem;
  font-weight: 400;
}

.cartitem .head {
  border-radius: 8px 8px 0px 0px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  background-color: white;
  margin-bottom: 20px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-location .main-location-a {
  display: flex;
  align-items: center;
}

.delivery-location {
  margin-bottom: 4px;
  margin-top: 15px;
  background-color: white;
  margin: 5px;
  border-radius: 8px;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 0;
  border: 1px solid #ddd;
  cursor: pointer;
  z-index: 10;
}

.delivery-location .location h3 {
  font-size: 1.3rem;
  color: red;
}

.location-details .p {
  color: gray;
}

.location-details p {
  margin: 4px;
  font-size: 16px;
  text-align: start;
}

.total-amount-cart {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 10;
}

.total-amount-cart .proceed-button-cart {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 4px solid white;
  box-shadow: 0px 0px 9px 0px black;
  width: 100%;
  padding: 12px 10px;
  background-color: #2957A4;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  text-align: center;
}

.total-amount-cart .total-amo-text2 {
  display: flex;
}

.total-amount-cart .total-amo-text2 p {
  margin-right: 4px;
}

.cart-item-add {
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 10px;
}

.cart-item-add .cart-item-info {
  display: flex;
}

.cart-item-add .cart-item-image {
  width: 26%;
  height: 85px;
  margin-right: 10px;
  border-radius: 5px;
}

.cart-item-add .cart-item-details {
  display: flex;
  flex-direction: column;
}

.cart-item-add .cart-item-name {
  font-weight: 500;
  margin-bottom: 5px;
}

.cart-item-add .cart-item-description {
  font-size: 13px;
  color: #555;
  margin-bottom: 10px;
  width: 95%;
}

.cart-item-add .cart-item-price {
  display: flex;
  align-items: center;
}

.cart-item-add .discounted-price {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}

.cart-item-add .original-price {
  font-size: 14px;
  color: #999;
  text-decoration: line-through;
}

.cart-item-add .quantity-control {
  display: flex;
  align-items: center;
}

.cart-item-add .quantity-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 0px;
  width: 14%;
  height: 26px;
  color: white;
  border-radius: 8px;
  background-color: #103E8A;
}

.cart-item-add .quantity-control span {
  font-size: 1.1rem;
  cursor: pointer;
  color: white;
}

.cart-item-add .btn {
  padding: 4px 0px;
  width: 14%;
  height: 26px;
  border-radius: 8px;
  background-color: white;
  color: #003366;
  border: 1px solid #003366;
}

/* ----Payment-page----- */
.payment-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1001;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 65%;
  background-color: white;
}

.payment-container .head .img {
  width: 7%;
}

.payment-container .head .img img {
  width: 100%;
}

.payment-container .head {
  border-radius: 8px 8px 0px 0px;
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 10px;
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-payment {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.payment-methods {
  width: 55%;
}

.payment-methods h2 {
  font-size: 1.5rem;
  margin-bottom: 6px;
  font-weight: 400;
}

.payment-option {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  background-color: white;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.cash-section {
  margin-top: 20px;
  margin-bottom: 10px;
}

.cash-section h3 {
  font-size: 1.2rem;
  margin-bottom: 5px;
}

.cash-section p {
  color: rgb(133, 183, 203);
}

.delivery-cart-summary {
  width: 42%;
  border: 1px solid #ccc;
  height: 350px;
}

.delivery-address {
  padding: 20px 20px 8px;
}

.delivery-address h4 {
  font-size: 1.2rem;
  color: grey;
  margin-bottom: 10px;
}

.delivery-address strong {
  color: gray;
}

.cart-summary .my-cart {
  display: flex;
  justify-content: space-between;
  background-color: rgb(194, 192, 192);
  padding: 8px 20px 0px;
}

.cart-summary h4 {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 400;
}

.payment-container .cart-payment {
  display: flex;
  padding: 20px 20px 8px;
}

.payment-container .cart-payment .img {
  width: 25%;
}

.payment-container .cart-payment .p {
  margin-top: 5px;
  margin-left: 10px;
}

.payment-container .cart-payment .p2 {
  color: grey;
}

.cart-payment img {
  border-radius: 8px;
  height: 100px;
  width: 100%;
  margin-right: 15px;
}

.pay-now-button {
  padding: 15px 20px 8px;
}

.pay-now-button button {
  width: 100%;
  padding: 10px;
  background-color: #2957A4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

/* ------Account-Section------- */

/* -----Merge----- */
.merge-data {
  display: flex;
  justify-content: center;
  height: 100%;
}

.merge-data .merge-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgb(244, 246, 251);
}

.merge-data .merge-row .merge-text {
  width: 20%;
  max-height: 585px;
  background-color: white;
  margin: 16px;
  border-radius: 15px 0px 0px 15px;
}

.merge-data .merge-row .merge-text-b {
  width: 80%;
}



/* -----Account-slide---- */
.account-slide .account-holder {
  padding: 22px 20px;
  background-color: #103E8A;
  color: white;
  border-radius: 15px 0px 0px 0px;
}

.account-slide .account-slide-item {
  padding: 26px 20px;
  display: flex;
  align-items: center;
}

.account-slide-item.active {
  background-color: #f0fdfd;
  border-left: 4px solid blue;
  width: 100% !important;
}


.account-slide a {
  text-decoration: none;
  color: black;
}

.account-slide .account-slide-item .text {
  font-size: 1.3rem;
  margin-right: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.account-slide .account-slide-item span svg {
  font-size: 25px;
  margin-right: 8px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.myorder-list .no-orders-message {
  text-align: center;
  font-size: 1.2em;
  color: #757575;
  margin-top: 20px;
}

/* -----Account-Profle----- */

.profile-main {
  width: 85%;
  margin: 50px auto;
  padding: 30px;
  background: linear-gradient(145deg, #f9f9f9, #ffffff);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.profile-main .profile-row {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-main .profile-img {
  position: relative;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
}

.profile-main .profile-img-b {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #103E8A;
  background-color: #f3f3f3;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.loader-img {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.profile-main .profile-img .img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-main .profile-img .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-main .edit-icon {
  position: absolute;
  bottom: 10px;
  right: 0px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 3px 5px 3px 7px;
  border-radius: 50%;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.profile-main .edit-icon label {
  cursor: pointer;
}

.profile-main .edit-icon svg {
  font-size: 1.5rem;
}

.profile-main .txt h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #333;
  text-align: center;
}

.profile-main .profile-form {
  width: 100%;
  margin-top: 30px;
}

.profile-main .form-container {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.profile-main .btn button {
  background-color: #103E8A;
  color: white;
  border: none;
  padding: 10px 25px;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 20px;
  -webkit-tap-highlight-color: transparent;
}

.profile-main .btn button:hover {
  background-color: #15489c;
  box-shadow: 0 4px 8px #15489c;
}

.profile-main .user-form .form-row {
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.profile-main .form-field {
  flex: 1;
  min-width: 230px;
}

.profile-main .form-field label {
  display: block;
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
  font-size: 1rem;
}

.profile-main .form-field input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: #333;
  transition: border-color 0.3s, box-shadow 0.3s;
  outline: none;
}

.profile-main .form-field input:focus {
  border: 1px solid #ccc;
  /* Prevent border change when focused */
  outline: none;
  /* Remove any focus outline */
}

.profile-main .form-field input:disabled {
  background-color: white;
  color: #333;
}

.profile-main .form-field input:focus {
  border-color: #15489c;
  box-shadow: 0 0 5px rgba(72, 174, 69, 0.5);
  outline: none;
}

.profile-main .form-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-main .form-submit button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #103E8A;
  color: white;
  border: none;
  padding: 12px 35px;
  font-size: 1.1rem;
  border-radius: 10px;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  -webkit-tap-highlight-color: transparent;
}

.profile-main .form-submit button:hover {
  background-color: #15489c;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px #15489c;
}

@media screen and (max-width: 768px) {
  .profile-main .profile-main {
    padding: 20px;
  }

  .profile-main .profile-img {
    width: 120px;
    height: 120px;
  }

  .profile-main .txt h3 {
    font-size: 1.2rem;
  }

  .profile-main .form-container {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .profile-main .profile-img {
    width: 100px;
    height: 100px;
  }

  .profile-main .txt h3 {
    font-size: 1.2rem;
  }

  .profile-main .form-row {
    flex-direction: column;
  }

  .profile-main .form-field {
    min-width: unset;
  }
}

/* ----Myorder----- */
.myorder-list {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.myorder-list .address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  border-radius: 0px 15px 0px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.myorder-list .address-header .title {
  font-size: 25px;
  color: #111827;
  padding: 20px 0px;
}

.myorder-list .fdf {
  overflow: scroll;
  max-height: 500px;
}

.myorder-list .order-card {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-bottom: 10px;

}

.myorder-list .order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  font-size: 14px;
  color: black;
  gap: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.myorder-list .order-header button {
  padding: 4px 5px;
  height: 30px;
  border-radius: 5px;
  background-color: #103E8A;
  color: white;
  font-size: .9rem;
  cursor: pointer;
}

.myorder-list .order-header span {
  margin-right: 6px;
  font-size: 1.17rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.myorder-list .order-items {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.myorder-list .order-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  color: #374151;
  transition: transform 0.3s ease;
}

.myorder-list .order-item:hover {
  transform: scale(1.01);
}

.myorder-list .order-item .item-image {
  width: 64px;
  height: 75px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
}

.myorder-list .item-name {
  color: #1f2937;
  font-size: 1.1rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  margin-top: 5px;
}

.myorder-list .item-quantity {
  font-weight: 500;
  font-size: 1.1rem;
}

.myorder-list .order-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.myorder-list .total {
  font-weight: bold;
  color: #111827;
  font-size: 1.17rem;
}

.myorder-list .order-details-button {
  background-color: transparent;
  border: none;
  color: #2563eb;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.myorder-list .order-details-button:hover {
  text-decoration: underline;
}

/* --------Promo Drawer------- */

.promo .promo-drawer {
  position: fixed;
  top: 0;
  right: 0;
  width: 30%;
  height: 100vh;
  background-color: white;
  box-shadow: -4px 0 10px rgba(0, 0, 0, 0.2);
  transition: right 0.3s ease-in-out;
  z-index: 1007;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow-y: auto;
}

.promo .promo .promo-drawer {
  right: 0;
}

.promo .promo-header {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 12px 10px;
  border-bottom: 1px solid #ddd;
  position: -webkit-sticky;
  position: sticky;
}

.promo .promo-header h2 {
  margin: 0;
}

.promo .close-button {
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
}

.promo .promo-heading {
  font-weight: 500;
}

/* Promo Content */
.promo .coupon-txt {
  padding: 10px 7px 0px 7px;
  background-color: #f0f4fa;
  display: flex;
  align-items: center;
}

.promo .coupon-txt h3 {
  font-weight: 500;
}

.promo .coupon-input {
  padding: 7px;
  background-color: #f0f4fa;
  display: flex;
  align-items: center;
}

.promo .coupon-input .btn {
  padding: 9px 0px;
  background-color: #103E8A;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  width: 25%;
  margin-left: 5px;
  cursor: pointer;
}

.promo .coupon-input .btn:disabled {
  background-color: gray;
  cursor: not-allowed;
}

.promo .coupon-input input {
  outline: none;
}

.promo .coupon-input input:focus {
  border: 1px solid #ccc;
  /* Prevent border change when focused */
  outline: none;
  /* Remove any focus outline */
}

.promo .coupon-input input {
  width: 100%;
  padding: 13px 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 15px;
}

.promo .promo-content {
  padding: 7px;
  overflow-y: auto;
  flex: 1;
  background-color: #f0f4fa;
}

.promo .promo-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.promo .promo-content .promo-item {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.promo .promo-card {
  display: flex;
  align-items: center;
  justify-content: start;
}

.promo .coupon-icon {
  font-size: 2rem;
  color: #0056d2;
  margin-right: 12px;
}

.promo .promo-content .promo-apply button {
  padding: 9px 0px;
  background-color: #103E8A;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  width: 30%;
  cursor: pointer;
}

.promo .promo-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.promo .promo-flex h2 {
  font-weight: 500;
  font-size: 1.4rem;
}

.promo .promo-content .promo-description {
  color: #103E8A;
  font-size: 14px;
  margin-top: 5px;
}

.promo .promo-apply {
  display: flex;
  justify-content: end;
}


.promo .background-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1004;
}

/* -------My Address------- */

.my-address .address-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  border-radius: 0px 15px 0px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 15px;
}

.my-address .title {
  font-size: 25px;
  color: #111827;
  padding: 20px 0px;
}

.my-address .add-new-address {
  color: #103E8A;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.my-address .add-new-address a {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  cursor: pointer;
  text-decoration: none;
  color: #0284c7;
}

.my-address .address-list {
  overflow: scroll;
  max-height: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 5px 16px
}

.my-address .add-new-address {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  font-size: 18px;
}

.my-address .add-icon {
  font-size: 36px;
  color: #6b7280;
}

.my-address .address-card {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 8px;
  padding: 16px;
  background-color: white;
  color: #111827;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  transition: transform 0.3s ease;
}

.my-address .address-card:hover {
  transform: scale(1.02);
}

.my-address .address-type {
  margin-bottom: 4px;
}

.my-address .address-type h2 {
  font-weight: 600;
  font-size: 1.2rem;
  margin-bottom: 4px;
}

.my-address .address-name h3 {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 4px;
}

.my-address .address-details {
  margin-bottom: 4px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.my-address .address-details span {
  color: rgb(18, 18, 18);
  font-size: 1rem;
}

.my-address .address-phone span {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 1rem;
}

.my-address .address-phone span .phone {
  color: gray;
}

.my-address .address-actions {
  display: flex;
  gap: 15px;
  justify-content: end;
}

.my-address .edit-button,
.delete-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  -webkit-tap-highlight-color: transparent;
}

.my-address .edit-button {
  font-size: 1.2rem;
  -webkit-tap-highlight-color: transparent;
  background-color: #103E8A;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.my-address .edit-button:hover {
  background-color: #15489c;
}

.my-address .delete-button {
  background-color: #f43f5e;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.2rem;
  -webkit-tap-highlight-color: transparent;
}

.my-address .delete-button:hover {
  background-color: #f85571;
}

/* ----Order-detail------ */

.account-order-detail {
  width: 100%;
  padding: 16px;
  font-family: Arial, sans-serif;
  color: #111827;
}

.account-order-detail .order-flexx {
  max-height: 500px;
  overflow: scroll;
}

.account-order-detail .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #ffffff;
  border-radius: 0px 15px 0px 0px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.account-order-detail .title {
  font-size: 25px;
  color: #111827;
  padding: 20px 0px;

}

.account-order-detail .help {
  -webkit-tap-highlight-color: transparent;
}

.account-order-detail .help {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 20px;
  background-color: #ffffff;
  cursor: pointer;
  color: #0284c7;
  font-size: 18px;
}

.account-order-detail .order-summary {
  border: 1px solid rgb(238, 238, 238);
  border-radius: 8px 8px 0px 0px;
  padding: 16px;
  margin-bottom: 10px;
  background-color: white;
}

.account-order-detail .order-info {
  font-size: 14px;
  font-size: 1.17rem;
  color: black;
  padding-bottom: 8px;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.account-order-detail .order-info .flex-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-order-detail .order-info .flex-3 button {
  padding: 4px 5px;
  height: 30px;
  border-radius: 5px;
  background-color: #103E8A;
  color: white;
  font-size: .9rem;
  cursor: pointer;
}

.account-order-detail .order-info span {
  margin-right: 6px;
  font-size: 1.17rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
}

.account-order-detail .items-header {
  font-weight: bold;
  margin: 8px 0;
}

.account-order-detail .order-item {
  display: flex;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.account-order-detail .order-item:last-child {
  border-bottom: none;
}

.account-order-detail .item-image {
  width: 64px;
  height: 75px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 4px;
}

.account-order-detail .item-details {
  flex: 1;
  font-size: 1.1rem;
}

.account-order-detail .item-name {
  font-weight: 500;
}

.account-order-detail .item-qty {
  margin-top: 15px;
}

.account-order-detail .item-qty span {
  color: black;
  font-size: 16px;
}

.account-order-detail .item-price {
  font-weight: 500;
  color: #111827;
  font-size: 16px;
  width: 50px !important;
}

.account-order-detail .order-totals {
  padding: 12px;
  background-color: white;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 0px 0px 8px 8px;
}

.account-order-detail .order-totals h3 {
  margin-bottom: 10px;
}

.account-order-detail .totals-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

.account-order-detail .totals-row span {
  font-size: 1.1rem;
}

.account-order-detail .grand-total {
  font-weight: bold;
  border-top: 1px dashed #6b7280;
  padding-top: 16px;
  font-size: 1.2rem;
}

/* -----Address-detail-form----- */

.main-address {
  margin: 16px;
}

.address-detail-form {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  width: 100%;
  background-color: #f0f4fa;
}

.required {
  color: red;
  font-weight: bold;
  margin-left: 5px;
}

.address-detail-form .address-box-text {
  padding: 10px;
  background-color: white;
  border-radius: 7px;
  margin-bottom: 5px;
}

.address-detail-form h3 {
  font-size: 1.4rem;
  font-weight: bold;
  padding: 10px 0px;
}

.address-detail-form .address-box {
  padding: 10px;
  background-color: white;
  border-radius: 7px;
}

.address-detail-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.address-detail-form label input[type="checkbox"] {
  margin-right: 8px;
}

.address-detail-form select {
  background: white !important;
}

.address-detail-form input {
  background: white !important;
}

.address-detail-form input[type="text"],
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: none;
  background: white !important;
}

.address-detail-form .form-row {
  display: flex;
  gap: 10px;
}

.address-detail-form .form-group {
  flex: 1;
  margin-top: 10px;
}

.address-detail-form .form-group .select {
  width: 50%;
  outline: none;
}

.address-detail-form .form-group .pin {
  width: 100%;
  padding: 10px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: none;
  background: white !important;
}

input:valid,
select:valid {
  /* background-color: white !important; */
  /* background: white !important; */
}

.address-detail-form .form-group .in {
  padding: 10px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  width: 100%;
  outline: none;
  background: white !important;
}

.address-detail-form .form-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.address-detail-form .cancel-button {
  background-color: #f0f4fa;
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.address-detail-form .save-button {
  background-color: #103E8A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.address-detail-form .save-button:hover {
  background-color: #15489c;
}

/* ---------Adress-detail-form-b------- */
.address-detail-form-b {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  width: 100%;
}

.address-detail-form-b .address-box-text {
  padding: 10px;
  background-color: white;
  border-radius: 7px;
  margin-bottom: 5px;
}

.address-detail-form-b .address-box {
  padding: 10px;
  background-color: white;
  border-radius: 7px;
}

.address-detail-form-b h3 {
  font-size: 1.4rem;
  font-weight: bold;
}

.address-detail-form-b select {
  background-color: white !important;
}

.address-detail-form-b input {
  background-color: white !important;
}

.address-detail-form-b label {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
}

.address-detail-form-b label input[type="checkbox"] {
  margin-right: 8px;
}

.address-detail-form-b input[type="text"],
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: none;
  background-color: white !important;
}

.address-detail-form-b .form-row {
  display: flex;
  gap: 10px;
}

.address-detail-form-b .form-group {
  flex: 1;
  margin-top: 10px;
}

.address-detail-form-b .form-group .select {
  width: 50%;
}

.form-group input {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-group input:focus {
  text-overflow: clip;
}

.address-detail-form-b .form-group .pin {
  width: 100%;
  padding: 10px;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: none;
}

.address-detail-form-b .form-group .in {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1.1rem;
  outline: none;
}

.address-detail-form-b .form-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.address-detail-form-b .cancel-button {
  background: transparent;
  color: #000;
  border: none;
  cursor: pointer;
  font-size: 17px;
}

.address-detail-form-b .save-button {
  background-color: #103E8A;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.address-detail-form-b .save-button:hover {
  background-color: #15489c;
}

.address-detail-form-b .error-message {
  color: red
}

/* --------Page-detail------ */
.book-details-container {
  display: flex;
  font-family: Arial, sans-serif;
  padding: 0px 20px 20px 20px;
  background-color: #f1f4fa;
  justify-content: space-between;
}

.image-magnifier {
  display: block;
}

@media (max-width: 900px) {
  .image-magnifier {
    display: none;
  }

  .main-image .img {
    display: block;
  }
}

.main-image .img {
  display: none;
}

.magnifer {
  width: 500px;
}

.page-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #103E8A;
  color: white;
  border-bottom: 1px black solid;
}

.page-heading .page-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.book-details-container .book-image-section {
  text-align: center;
  border: 1px solid #ddd;
  background-color: white;
  width: 50%;
}

.book-details-container .main-image {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.book-details-container .main-image .p {
  position: absolute;
  z-index: 100;
  width: 90%;
  padding: 10px;
  bottom: 0px;
  text-align: center;
  background-color: white;
  color: black;
  opacity: 0.8;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-details-container .main-image img {
  width: 50%;
  height: 400px !important;
  padding: 15px;
}

.book-details-container .thumbnail-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  gap: 10px;
  margin-bottom: 10px;
}

.book-details-container .thumbnail {
  width: 70px;
  height: 80px;
  cursor: pointer;
  border-radius: 5px;
}

.book-details-container .thumbnail:hover {
  border-color: #888;
}

.book-details-container .thumbnail.active {
  border-color: #007bff;
  box-shadow: 0px 0px 5px rgba(0, 123, 255, 0.5);
}

.book-details-container .product-details-section {
  width: 50%;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: white;
}

.book-details-container .product-details-section .detail ol {
  margin-left: 18px;
  margin-top: 5px;
}

.book-details-container .title {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
}

.book-details-container .title {
  font-size: 1.2em;
  font-weight: bold;
  border-bottom: 1px solid rgb(238, 238, 238);
}

.book-details-container .txt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 12px;
}

.book-details-container .txt .txt-b {
  display: flex;
  justify-content: center;
  align-items: center;
}

.book-details-container .txt .add-btn {
  display: flex;
  padding: 7px 20px;
  background-color: #103E8A;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  width: 112px;
  height: 37px;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
}

.book-details-container .add-to-cart-button {
  padding: 7px 20px;
  background-color: #103E8A;
  height: 37px;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  border: 1px grey solid;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.book-details-container .add-to-cart-button button {
  color: white;
  cursor: pointer;
}

.book-details-container .txt .add-btn span {
  font-size: 1.2rem;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.book-details-container .txt .add-btn p {
  font-size: 1.2rem;
  color: white;
}

.book-details-container .launch-info {
  font-size: 0.9em;
  color: #888;
}

.book-details-container .price {
  font-size: 1.2em;
  color: #333;
  margin: 5px 0;
}

.book-details-container .price-info {
  font-size: 1.2em;
  margin: 5px 0;
  color: #888;
  margin-left: 5px;
}

.book-details-container .section-title {
  margin-top: 15px;
  font-size: 1.1em;
  font-weight: bold;
  margin-bottom: 15px;
}

.book-details-container .detail {
  margin: 5px 0 10px;
}

.book-details-container .highlights-list {
  margin-left: 15px;
}

.book-details-container .highlights-list li {
  margin: 5px 0;
}

/* -----Change password----- */

.change-password-container {
  font-family: Arial, sans-serif;
  padding: 16px;
  width: 100%;
  margin: auto;
  text-align: left;
  display: flex;
  justify-content: space-between;
}

.change-password-container h2 {
  color: #2c2c2c;
  font-size: 26px;
  margin-bottom: 20px;
}

.change-password-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60%;
}

.change-password-container .input-group {
  position: relative;
}

.change-password-container label {
  display: block;
  font-size: 17px;
  margin-bottom: 8px;
}

.change-password-container input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.change-password-container input.valid {
  border-color: green;
}

.change-password-container .checkmark {
  position: absolute;
  right: 10px;
  top: 88px;
  color: green;
  font-size: 18px;
}

.change-password-container .change-password-button {
  background-color: rgb(90, 90, 244);
  color: white;
  border: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.change-password-container .change-password-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.change-password-container .password-requirements {
  margin-top: 20px;
  width: 38%;
}

.change-password-container h4 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.change-password-container ul {
  list-style: none;
  padding: 0;
}

.change-password-container li {
  font-size: 15px;
  color: #666;
}

.change-password-container li.met {
  color: green;
  text-decoration: line-through;
}

/* -------Conformed-order-page----- */

.modal-overlay-c .confirmation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.modal-overlay-c .confirmation-box {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.modal-overlay-c .check-icon {
  color: #103E8A;
  font-size: 36px;
  margin-bottom: 20px;
}

.modal-overlay-c .check-icon span {
  border-radius: 50%;
  border: #103E8A 1px solid;
  padding: 5px 10px;
}

.modal-overlay-c h2 {
  color: #333;
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-overlay-c h1 {
  color: #333;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.modal-overlay-c p {
  color: #666;
  font-size: 14px;
  margin-bottom: 30px;
}

.modal-overlay-c .check-status-button {
  background-color: #ff0080;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal-overlay-c .check-status-button:hover {
  background-color: #e00072;
}

/* ------Loading-screen----- */

/* Center the spinner */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

/* Spinner styling */
.loading-container .spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #ddd;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Loading text styling */
.loading-container p {
  margin-top: 10px;
  font-size: 18px;
  color: #333;
}

/* -------Tracking-order-------- */
.track-order-container {
  min-height: 100vh;
  background-color: #f3f4f6;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.track-order-container .track-order-box {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  text-align: center;
}

.track-order-container .track-order-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.track-order-container .track-order-steps {
  border-top: 2px solid #ddd;
  padding-top: 15px;
}

.track-order-container .steps-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  list-style: none;
}

.track-order-container .step-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.track-order-container .step-icon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: white;
}

.track-order-container .completed {
  background-color: #10b981;
}

.track-order-container .pending {
  background-color: #9ca3af;
}

.track-order-container .step-text {
  margin-top: 8px;
  font-size: 0.9rem;
  color: #555;
}

.track-order-container .step-line-a {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ddd;
  top: 20px;
  left: 80px;
}

.track-order-container .order-details {
  background: #f9fafb;
  padding: 15px;
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
}

.track-order-container .details-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.track-order-container .details-text {
  font-size: 1.1rem;
  color: #555;
}

.track-order-container .details-text span {
  font-weight: bold;
  color: #333;
}





/* ===========RESPONSIVE=========== */
/* ---navbar---- */

@media (min-width: 1280px) and (max-width: 1600px) {
  .springboard-notes .books-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    background: rgb(244, 246, 251);
    padding: 10px 0px 10px 10px;
  }
}


@media (min-width: 900px) and (max-width: 1280px) {
  .navbar .header-top {
    width: 100%;
  }

  .navbar .header-top p {
    font-weight: 400;
    font-size: .9rem;
    width: 25%;
  }

  .navbar .header-top .p {
    width: 50%;
  }

  .navbar .header-top a {
    color: black;
    text-decoration: none;
    margin-left: 1rem;
  }

  .navbar .header-links {
    width: 32%;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 205px;
  }

  .featuredbrand .book {
    height: 335px;
  }

  .cart-container {
    width: 40%;
  }

  .new-address-form {
    width: 100%;
    height: 100vh;
  }

  .cartitem {
    width: 40%;
  }

  .account-slide .account-slide-item {
    padding: 19px 15px;
    display: flex;
    align-items: center;
    font-size: 1.1rem;
  }

  .footer-container .footer-logo {
    width: 66%;
  }

  .footer-container .footer-column hr {
    width: 77%;
  }

  .footer-container .footer-social-media a {
    display: inline;
    justify-content: space-evenly;
  }

  .account-slide .account-holder {
    padding: 15px 20px;
  }

  .account-slide .account-slide-item span {
    font-size: 1.2rem;
  }

  .cart-item .cart-item-image {
    width: 20%;
  }

  .filter .nav-item .img {
    width: 73px;
    height: 73px;
  }

  .navbar .cart-button {
    height: 55px;
    width: 115px;
  }

  .cart-container {
    width: 40%;
  }

  .new-address-form {
    width: 40%;
  }

  .promo .promo-drawer {
    width: 40%;
  }

  .book-details-container .main-image img {
    height: 310px;
  }

  .book-details-container .title {
    font-size: 1.1em;
  }

  /* ------filter-category----- */
  .springboard-notes .books-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    padding: 8px 0px 8px 8px;
  }

  .main-sort .text-book {
    padding: 10px 17px 10px 17px;
  }

  .springboard-notes .book-card .h3 {
    height: 60px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 350px !important;
  }

  .springboard-notes .book-card {
    width: 24.2%;
    height: 335px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: white;
  }

  .springboard-notes .book-image a {
    width: 90%;
    display: flex;
    height: 217px;
  }

  .springboard-notes .book-image .p {
    height: 217px;
  }

}

@media (min-width: 1100px) and (max-width: 1160px) {
  .springboard-notes .book-card {
    width: 24%;
  }

}

@media (min-width: 800px) and (max-width: 900px) {
  .springboard-notes .book-image a {
    height: 265px;
  }
}

@media (min-width: 900px) and (max-width: 1100px) {
  .springboard-notes .book-card {
    width: 24%;
    height: 334px;
  }

  .springboard-notes .book-image a {
    height: 215px;
  }

  .springboard-notes .books-container {
    gap: 8px;
  }
}

@media (min-width: 900px) and (max-width: 950px) {

  .login-modal-header-2 {
    display: block;
    cursor: pointer;
    font-family: CustomFont¸;
    padding: 8px 10px;
    font-size: 20px;
    left: 15px;
    top: 15px;
    z-index: 100001;
    color: rgb(31, 31, 31);
    position: fixed;
    outline: none;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 50%;
  }

  .position-div {
    position: relative;
    top: 18%;
  }

  .position-div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position-div-2 .position-flex {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .login-modal-number .login-modal-header {
    display: none;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
  }

  .navbar .header-top p {
    font-weight: 400;
    font-size: .86rem;
    width: 25%;
  }

  .navbar .header-top a {
    color: black;
    text-decoration: none;
    margin-left: .6rem;
  }

  .cart-item .cart-item-image {
    width: 25%;
  }

}

@media (min-width: 750px) and (max-width: 900px) {
  .white-screen {
    margin-top: 25%;
  }

  .login-modal-header-2 {
    display: block;
    cursor: pointer;
    font-family: CustomFont;
    padding: 8px 10px;
    font-size: 20px;
    left: 15px;
    top: 15px;
    z-index: 100001;
    color: rgb(31, 31, 31);
    position: fixed;
    outline: none;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 50%;
  }

  .position-div {
    position: relative;
    top: 18%;
  }

  .position-div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position-div-2 .position-flex {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .login-modal-number .login-modal-header {
    display: none;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
  }

  .navbar {
    display: none;
  }

  .res-navbar {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0fdfd;
  }

  .featuredbrand .book {
    height: 355px;
  }

  .res-navbar .account {
    position: relative;
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links {
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .res-navbar .header-links .dropdown-menu {
    position: absolute;
    top: 55px;
    width: 79%;
    left: -50px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 0;
    animation: dropdownFadeIn 0.3s ease;
    border-radius: 4px;
  }

  .res-navbar .header-links .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-navbar .header-links .dropdown-menu ul li {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    font-size: 16px;
    color: #333;
  }

  .res-navbar .header-links .dropdown-menu ul li svg {
    margin-right: 7px;
  }

  .res-navbar .header-links {
    position: relative;
    margin-left: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: auto;
    padding: 10px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    justify-content: space-evenly;
  }

  .res-navbar .header-links i {
    font-size: 1.5rem;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .res-navbar .logo h3 {
    display: none;
  }

  .address-modal {
    display: flex;
    justify-content: space-between;
    background-color: #f1f4fa;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
  }

  .res-navbar .logo {
    width: 30%;
    display: flex;
    align-items: center;
  }

  .res-navbar .logo h3 {
    margin-right: 15px;
    font-size: 1.4rem;
  }

  .res-navbar .logo .img {
    width: 38%;
    object-fit: cover;
  }

  .res-navbar .logo a {
    width: 100%;
  }

  .res-navbar .logo img {
    width: 100%;
    height: auto;
  }

  .res-navbar .res-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }

  .res-navbar .res-btn .user span {
    margin-left: 4px;
  }

  .res-navbar .res-btn .cart span {
    margin-left: 4px;
  }

  .res-nav-b {
    background-color: #f0fdfd;
    padding: 0px 10px 10px;
  }

  .res-nav-b .search-bar {
    display: flex;
    width: 100%;
    position: relative;
    display: block;
  }

  .res-nav-b .search-bar .input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .res-nav-b .search-bar .input input {
    width: 100%;
    padding: 0.75rem 4px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
  }

  .res-nav-b .search-bar .input svg {
    font-size: 1.5rem;
  }

  .res-nav-b .search-bar button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1rem;
  }

  .res-main .dropdown-menu .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .res-main .dropdown-menu-b .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu-b ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu-b li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu-b .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu-b ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu-b ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .cart-container {
    width: 30%;
    height: 106vh;
  }

  /* ---filter---- */
  .filter-category .filter {
    display: flex;
    align-items: start;
    background-color: white;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-height: 350px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: 5px;
    flex-direction: column;
  }

  .filter .nav-item .img {
    width: 60px;
    height: 60px;
  }

  .filter .nav-item .filter-link {
    margin-right: 10px;
  }

  .filter .nav-item {
    width: calc(100% / 4);
  }

  .filter-category {
    margin-top: 0px;
  }

  .swiper {
    width: 100%;
    height: 200px;
  }

  .featuredbrand .text h2 {
    font-size: 2.2rem;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 230px;
  }

  .slick-next {
    right: 5px;
    color: black;
  }

  .slick-prev,
  .slick-next {
    font-size: 18px;
  }

  .slick-prev {
    left: 5px;
    color: black;
  }

  .featuredbrand .book .book-btn button {
    width: 65px;
    height: 28px;
  }

  .featuredbrand .book .book-btn .add-btn {
    width: 65px;
    height: 28px;
  }

  .cart-container {
    width: 55%;
    height: 100vh;
  }

  .remove-btn button {
    width: 22%;
    height: 28px;
  }

  .login-modal {
    max-width: 380px;
  }

  .cart-container {
    width: 50%;
  }

  .new-address-form {
    width: 50%;
  }

  .promo .promo-drawer {
    width: 50%;
  }

  .cart-item .cart-item-image {
    width: 20%;
  }

  .res-btn .cart-button {
    background-color: #103E8A;
    color: white;
    border: none;
    border-radius: 8px;
    height: 45px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow: hidden;
    margin-left: 15px;
  }

  .res-btn .cart-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 5px;
  }

  .res-btn .cart-info .number {
    font-size: 17px;
  }

  .res-btn .cart-info .price {
    font-size: 17px;
  }

  .merge-data .merge-row .merge-text {
    display: none;
  }

  .merge-data .merge-row .merge-text-b {
    width: 100%;
  }

  .book-details-container .main-image img {
    width: 70%;
    height: 330px;
  }

  .book-details-container .title {
    font-size: 1rem;
    font-weight: bold;
  }

  .featuredbrand .text h2 {
    font-size: 1.9rem;
  }

  .footer-container .footer-logo {
    width: 80%;
  }

  .footer-container .footer-logo img {
    width: 100%;
    height: 150px;
  }

  .footer-container .footer-column .footer-p {
    font-size: 1rem;
  }

  .footer-container .footer-column hr {
    width: 100%;
  }

  .footer-container .footer-social-media a {
    margin: 0px 7px;
    justify-content: space-evenly;
    display: inline;
    padding: 5px 8px;
    font-size: 1.4rem;
  }

  .footer-container .footer-column h3 {
    font-size: 1.3rem;
  }

  .footer-container .footer-column ul li {
    font-size: 1rem;
  }

  .address-detail-form-b .error-message {
    color: red;
    font-size: 14px;
  }

  .address-detail-form-b .form-group {
    flex: 1 1;
    margin-top: 0px;
  }

  /* -----filter-category---- */
  .text-book h2 {
    font-size: 1.6rem;
  }

  .main-sort .text-book .price-select select {
    margin-bottom: 0;
    width: 40%;
  }

  .springboard-notes .book-card {
    width: 32%;
    height: 325px;
  }

  .springboard-notes .books-container {
    gap: 24px;
    padding: 27px 0px 0px 20px;
  }

  .springboard-notes .price-section button {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .price-section .add-btn {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .left-book .flex .img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .springboard-notes .left-book .flex .img-a {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .text {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .img {
    border-radius: 14%;
    height: 82px;
    width: 68%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .springboard-notes .left-book .flex h3 {
    margin-left: 0px;
    font-weight: 500;
    font-size: 15px;
    width: 100%;
    text-align: center;
  }

  .springboard-notes .left-book .flex-2 {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 10px;
  }

  .springboard-notes .left-book {
    width: 20%;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .springboard-notes .books-container {
    gap: 10px;
    padding: 8px 0px 8px 8px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 15px;
  }

  .springboard-notes .book-image a {
    height: 210px;
  }

  .springboard-notes .book-image .p {
    height: 210px;
  }

  .main-sort .text-book {
    padding: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    text-align: start;
  }

  .springboard-notes .book-card .h3 {
    height: 60px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 346px !important;
  }

  .springboard-notes .book-card h3 {
    height: 40px;
  }

  .main-image .img {
    display: block;
  }
  .modal-contenttt {
    width: 90%;
  }

}

@media (min-width: 650px) and (max-width: 750px) {
  .main-image .img {
    display: block;
  }

  .springboard-notes .book-card {
    width: 46%;
    height: 342px;
  }
  

}

@media (min-width: 600px) and (max-width: 750px) {
  .modal-contenttt {
    width: 90%;
  }
  .white-screen {
    margin-top: 30%;
  }

  .animated-text {
    font-size: 25px;
    color: #666;
    animation: pulse 1.5s ease-in-out infinite;
    text-align: center;
  }

  .login-modal-header-2 {
    display: block;
    cursor: pointer;
    font-family: CustomFont;
    padding: 8px 10px;
    font-size: 20px;
    left: 15px;
    top: 15px;
    z-index: 100001;
    color: rgb(31, 31, 31);
    position: fixed;
    outline: none;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 50%;
  }

  .position-div {
    position: relative;
    top: 18%;
  }

  .position-div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position-div-2 .position-flex {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .login-modal-number .login-modal-header {
    display: none;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
  }

  .featuredbrand .book-img .p {
    height: 185px;
  }

  .main-image .img {
    display: block;
  }

  .res-navbar .account {
    position: relative;
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links {
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .res-navbar .header-links .dropdown-menu {
    position: absolute;
    top: 55px;
    width: 79%;
    left: -50px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 0;
    animation: dropdownFadeIn 0.3s ease;
    border-radius: 4px;
  }

  .res-navbar .header-links .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .featuredbrand .book {
    height: 310px;
  }

  .res-navbar .header-links .dropdown-menu ul li {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    font-size: 16px;
    color: #333;
  }

  .res-navbar .header-links .dropdown-menu ul li svg {
    margin-right: 7px;
  }

  .res-navbar .header-links {
    position: relative;
    margin-left: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: auto;
    padding: 10px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    justify-content: space-evenly;
  }

  .res-navbar .header-links i {
    font-size: 1.5rem;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .res-navbar .logo h3 {
    display: none;
  }

  .navbar {
    display: none;
  }

  .res-navbar {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0fdfd;
  }

  .res-navbar .logo {
    width: 30%;
    display: flex;
    align-items: center;
  }

  .res-navbar .logo h3 {
    margin-right: 15px;
    font-size: 1.4rem;
  }

  .res-navbar .logo .img {
    width: 38%;
    object-fit: cover;
  }

  .res-navbar .logo a {
    width: 100%;
  }

  .res-navbar .logo img {
    width: 100%;
    height: auto;
  }

  .res-navbar .res-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }

  .res-navbar .res-btn .user span {
    margin-left: 4px;
  }

  .res-navbar .res-btn .cart span {
    margin-left: 4px;
  }

  .res-nav-b {
    background-color: #f0fdfd;
    padding: 0px 10px 10px;
  }

  .res-nav-b .search-bar {
    display: flex;
    width: 100%;
    position: relative;
    display: block;
  }

  .res-nav-b .search-bar .input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .res-nav-b .search-bar .input input {
    width: 100%;
    padding: 0.75rem 4px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
  }

  .res-nav-b .search-bar .input svg {
    font-size: 1.5rem;
  }

  .res-nav-b .search-bar button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1rem;
  }

  .address-modal {
    display: flex;
    justify-content: space-between;
    background-color: #f1f4fa;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
  }

  .res-main .dropdown-menu .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .res-main .dropdown-menu-b .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu-b ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu-b li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu-b .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu-b ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu-b ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .cart-container {
    width: 30%;
    height: 106vh;
  }

  /* ---filter---- */
  .filter-category .filter {
    display: flex;
    align-items: start;
    background-color: white;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-height: 350px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: 5px;
    flex-direction: column;
  }

  .filter-category .filter .nav-item {
    width: 16.7%;
  }

  .filter .nav-item .img {
    width: 60px;
    height: 60px;
  }

  .filter .nav-item .filter-link {
    margin-right: 10px;
  }

  .filter .nav-item {
    width: calc(100% / 4);
  }

  .filter-category {
    margin-top: 0px;
  }

  .swiper {
    width: 100%;
    height: 200px;
  }

  .featuredbrand .text h2 {
    font-size: 2.2rem;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 185px;
  }

  .slick-next {
    right: 5px;
    color: black;
  }

  .slick-prev,
  .slick-next {
    font-size: 18px;
  }

  .slick-prev {
    left: 5px;
    color: black;
  }

  .featuredbrand .book .book-btn button {
    width: 60px;
    height: 26px;
  }

  .featuredbrand .book .book-btn .add-btn {
    width: 60px;
    height: 26px;
  }

  .cart-container {
    width: 55%;
    height: 100vh;
  }

  .remove-btn button {
    width: 22%;
    height: 28px;
  }

  .login-modal {
    max-width: 380px;
  }

  .new-address-form {
    width: 55%;
  }

  .cart-item .cart-item-image {
    width: 26%;
  }

  .res-btn .cart-button {
    background-color: #103E8A;
    color: white;
    border: none;
    border-radius: 8px;
    height: 45px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow: hidden;
    margin-left: 15px;
  }

  .res-btn .cart-info {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 5px;
  }

  .res-btn .cart-info .number {
    font-size: 17px;
  }

  .res-btn .cart-info .price {
    font-size: 17px;
  }

  .merge-data .merge-row .merge-text {
    display: none;
  }

  .merge-data .merge-row .merge-text-b {
    width: 100%;
  }

  .book-details-container {
    display: block;
  }

  .book-details-container .main-image img {
    width: 70%;
    height: 415px;
  }

  .book-details-container .book-image-section {
    width: 100%;
  }

  .book-details-container .product-details-section {
    width: 100%;
  }

  .featuredbrand .text h2 {
    font-size: 1.9rem;
  }

  .footer-container .footer-content {
    width: 100%;
    padding: 10px 18px;
  }

  .footer-container .footer-links {
    flex-wrap: wrap;
  }

  .footer-container .footer-column {
    width: 50%;
    margin-top: 10px;
  }

  .footer-container .footer-logo {
    width: 50%;
  }

  .footer-container .footer-column h3 {
    font-size: 1.3rem;
  }

  .footer-container .footer-column ul li {
    font-size: 1rem;
  }

  .footer-container .footer-column .footer-p {
    font-size: 1rem;
  }

  .footer-container .footer-social-media a {
    margin: 0px 3px;
    padding: 5px 6px;
    font-size: 1.2rem;
    width: 20%;
  }

  .promo .promo-drawer {
    width: 55%;
  }

  .address-detail-form-b .form-group {
    flex: 1 1;
    margin-top: 0px;
  }

  /* -----filter-category---- */
  .main-sort .text-book h2 {
    font-size: 1.4rem;
  }

  .main-sort .text-book .price-select select {
    margin-bottom: 0;
    width: 50%;
  }

  .springboard-notes .book-card .h3 {
    height: 60px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 358px !important;
  }

  .springboard-notes .book-card {
    width: 48.9%;
    height: 342px;
  }

  .springboard-notes .price-section button {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .price-section .add-btn {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .left-book .flex .img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .springboard-notes .left-book .flex .img-a {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .text {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .img {
    border-radius: 14%;
    height: 65px;
    width: 72%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .springboard-notes .left-book .flex h3 {
    margin-left: 0px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: center;
  }

  .springboard-notes .left-book .flex-2 {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 10px;
  }

  .springboard-notes .left-book {
    width: 22%;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .springboard-notes .books-container {
    gap: 6px;
    padding: 6px 0px 6px 6px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 15px;
  }

  .main-sort .text-book {
    padding: 10px 10px 10px 10px;
    display: flex;
    align-items: center;
    text-align: start;
  }

  .springboard-notes .book-image a {
    height: 225px;
  }

  .springboard-notes .book-image .p {
    height: 225px;
  }
}

@media screen and (max-width: 750px) {
  .address-modal {
    max-height: 730px;
  }

  .address-detail-form-b {
    margin: 0 auto;
    font-family: Arial, sans-serif;
    width: 100%;
    max-height: 730px;
  }

  .address-detail-form-b .address-box {
    max-height: 630px;
    overflow: scroll;
    scrollbar-width: none;
  }
}

@media screen and (max-width: 600px) {
  .modal-contenttt {
    width: 95%;
  }
  .aboutus-container .hero-section {
    padding: 10px 20px;
}
  .new-address-form .form-text {
    overflow-y: auto;
    max-height: 630px;
  }

  .account-order-detail .order-info .flex-3 {
    display: block;
  }

  .account-order-detail .order-info .flex-3 .btn {
    display: flex;
    justify-content: end;
    margin-top: 5px;
  }

  .page-heading .page-head {
    font-size: 14px;
  }

  .account-order-detail .order-info span {
    font-weight: 400;
  }

  .myorder-list .order-header span {
    font-weight: 400;
  }

  .myorder-list .item-name {
    font-weight: 400;
  }

  .account-order-detail .order-info {
    font-weight: 400;
  }

  .white-screen {
    margin-top: 40%;
  }

  .animated-text {
    font-size: 25px;
    color: #666;
    animation: pulse 1.5s ease-in-out infinite;
    text-align: center;
  }

  .login-modal-header-2 {
    display: block;
    cursor: pointer;
    font-family: CustomFont;
    padding: 8px 10px;
    font-size: 20px;
    left: 15px;
    top: 15px;
    z-index: 100001;
    color: rgb(31, 31, 31);
    position: fixed;
    outline: none;
    border: none;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 0px;
    border-radius: 50%;
  }

  .position-div {
    position: relative;
    top: 18%;
  }

  .position-div-2 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .position-div-2 .position-flex {
    position: absolute;
    top: 30%;
    width: 100%;
  }

  .login-modal-number .login-modal-header {
    display: none;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .profile-main .form-field .inp {
    width: 100% !important;
  }

  .book-details-container .main-image .p {
    position: absolute;
    z-index: 100;
    width: 100%;
    padding: 10px;
    bottom: 0px;
    text-align: center;
    background-color: white;
    color: black;
    opacity: 0.8;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-image .img {
    display: block;
  }

  .main-sort .text-book {
    display: none;
  }

  .sort-2 .text-book .price-select {
    display: none;
  }

  .sort-2 .text-book {
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    background-color: white;
    padding: 10px 30px 10px 10px;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 130px;
    z-index: 100;
  }

  .discount-badge-book-sub {
    font-size: 8px;
  }

  .discount-badge {
    font-size: 10px;
  }

  .main-sort .text-book {
    height: 55px;
  }

  .res-navbar .header-links .user {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-sort .text-book .price-select {
    display: none;
  }

  .res-navbar .account {
    position: relative;
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links {
    display: flex;
    align-items: center;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .filter-category .filter .swiper-horizontal {
    display: block;
  }

  .res-navbar .header-links .dropdown-menu {
    position: absolute;
    top: 55px;
    width: 79%;
    left: -50px;
    background-color: #fff;
    border: 1px solid #ddd;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px 0;
    animation: dropdownFadeIn 0.3s ease;
    border-radius: 4px;
  }

  .res-navbar .header-links .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-navbar .header-links .dropdown-menu ul li {
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: left;
    font-size: 16px;
    color: #333;
  }

  .res-navbar .header-links .dropdown-menu ul li svg {
    margin-right: 7px;
  }

  .res-navbar .header-links {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: auto;
    padding: 10px 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    justify-content: space-evenly;
  }

  .res-navbar .header-links i {
    font-size: 1.5rem;
  }

  .res-navbar .header-links span {
    margin: 2px;
    font-size: 1.1rem;
  }

  .res-navbar .logo h3 {
    display: none;
  }

  .address-modal {
    display: flex;
    justify-content: space-between;
    background-color: #f1f4fa;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
  }

  .featuredbrand .book-img .p {
    height: 225px;
  }

  .navbar {
    display: none;
  }

  .address-detail-form-b .form-group {
    flex: 1 1;
    margin-top: 0px;
  }

  .res-navbar {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0fdfd;
  }

  .res-navbar .logo {
    width: 30%;
    display: flex;
    align-items: center;
  }

  .res-navbar .logo h3 {
    margin-right: 15px;
    font-size: 1.4rem;
  }

  .res-navbar .logo .img {
    width: 38%;
    object-fit: cover;
  }

  .res-navbar .logo a {
    width: 100%;
  }

  .res-navbar .logo img {
    width: 100%;
    height: auto;
  }

  .res-navbar .res-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  }

  .res-navbar .res-btn .user span {
    margin-left: 4px;
  }

  .res-navbar .res-btn .cart span {
    margin-left: 4px;
  }

  .res-nav-b {
    background-color: #f0fdfd;
    padding: 0px 10px 8px;
  }

  .res-nav-b .search-bar {
    display: flex;
    width: 100%;
    position: relative;
    display: block;
  }

  .res-nav-b .search-bar .input {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 10px;
    border-radius: 10px;
    border: none;
    outline: none;
    background-color: white;
    display: flex;
    align-items: center;
  }

  .res-nav-b .search-bar .input input {
    width: 100%;
    padding: 0.75rem 4px;
    border-radius: 10px;
    border: none;
    outline: none;
    background: none;
    font-size: 1rem;
  }

  .res-nav-b .search-bar .input svg {
    font-size: 1.5rem;
  }

  .res-nav-b .search-bar button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 1rem;
  }

  .res-main .dropdown-menu .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu ul li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  .res-main .dropdown-menu-b .drop-text {
    margin-bottom: 10px;
  }

  .res-main .dropdown-menu-b ul li {
    padding: 10px 0px 10px;
    font-size: 1.1rem;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .res-main .dropdown-menu ul li svg {
    margin-right: 5px;
  }

  .res-main .dropdown-menu-b li:hover {
    background-color: #E3FFFD;
    color: #000;
  }

  .res-main .dropdown-menu-b .drop-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .res-main .dropdown-menu-b ul a {
    text-decoration: none;
    color: black;
  }

  .res-main .dropdown-menu-b ul a li:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  /* ------Filter----- */
  .filter-category .filter {
    display: flex;
    align-items: start;
    background-color: white;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-height: 350px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: 5px;
    flex-direction: column;
  }

  .filter .nav-item .img {
    width: 60px;
    height: 60px;
  }

  .filter .nav-item .filter-link {
    width: 100%;
  }

  .filter-category .filter .nav-item .filter-link {
    height: 98px;
  }

  .filter-category .filter .nav-item {
    display: flex;
    width: 29%;
  }

  .login-modal-number .login-input {
    width: 60%;
    padding: 11px;
    border: 1px solid #ccc
  }

  .filter-category {
    margin-top: 0px;
  }

  .swiper {
    width: 100%;
    height: 215px;
  }

  /* ----Category----- */
  .featuredbrand .text h2 {
    font-size: 2rem;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 225px;
  }

  .slick-next {
    right: 5px;
    color: black;
  }

  .slick-prev {
    left: 5px;
    color: black;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px;
    padding: 6px 8px;
  }

  .featuredbrand .book .book-btn .price {
    display: block;
  }

  .featuredbrand .book .book-btn .original-price {
    margin-left: 0px;
    margin-top: 2px;
  }

  .featuredbrand .book .book-btn button {
    width: 60px;
    height: 27px;
    font-size: .8rem;
  }

  .featuredbrand .book .book-btn .add-btn {
    width: 60px;
    height: 27px;
  }

  .footer-container .footer-links {
    flex-wrap: wrap;
  }

  .footer-container .footer-column {
    width: 50%;
    margin-top: 10px;
  }

  .footer-container .footer-social-media a {
    margin: 0px 5px;
    padding: 2px 4px;
  }

  .footer-container .footer-content {
    width: 100%;
    padding: 10px 18px;
  }

  /* --cart--- */
  .cart-container {
    width: 100%;
  }

  .cart-item .cart-item-image img {
    height: 65px;
    width: 100%;
    border-radius: 5px;
  }

  .cart-container .cart-header h2 {
    font-weight: 400;
    font-size: 19px;
  }

  .promo .promo-flex h2 {
    font-weight: 400;
    font-size: 19px;
  }

  .cart-item .cart-item-name {
    font-size: 1rem;
  }

  .cart-item .btn {
    width: 100%;
  }

  .cart-item .cart-item-info {
    display: flex;
  }

  .cart-item .cart-item-image {
    width: 15%;
  }

  .featuredbrand .book {
    height: 340px;
  }

  .remove-btn button {
    font-size: .7rem;
    width: 25%;
    height: 30px;
  }

  .promo-code span {
    font-size: 15px;
  }

  .promo-code .arrow {
    font-size: 18px;
  }

  .promo-code {
    padding: 15px 10px;
  }

  .bill-details .gift {
    font-size: 1.2rem;
  }

  .total-row {
    font-size: 17px;
  }

  .total-amount .proceed-button {
    padding: 15px 10px;
    font-size: 18px;
  }

  .res-btn .cart-button {
    background-color: #103E8A;
    color: white;
    border: none;
    border-radius: 8px;
    height: 40px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    overflow: hidden;
    margin-left: 15px;
  }

  .res-btn .cart-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .res-btn .cart-info .number {
    font-size: 15px;
  }

  .res-btn .cart-info .price {
    font-size: 15px;
  }

  .res-btn .cart-icon {
    font-size: 1.2em;
    margin-right: 4px;
  }

  .res-btn .cart-info span {
    font-size: 0.8em;
    line-height: 1;
  }

  .res-btn .cart-text {
    font-size: 0.9em;
  }

  /* ---Footer--- */
  .footer-container .footer-logo img {
    width: 100%;
    height: 132px;
  }

  /* ---login-page-- */
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    border-radius: 0px !important;
  }

  .login-modal {
    top: 50%;
    left: 50%;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
  }

  .login-modal .text-cart h2 {
    font-size: 1.4rem;
  }

  .login-modal .login-button {
    font-size: 17px;
  }

  .new-address-form {
    width: 100%;
    height: 100vh;
  }

  .new-address-form .text h2 {
    font-weight: 400;
    font-size: 19px;
  }

  .new-address-form .form-lite .icon span {
    font-size: 1.2rem;
  }

  .new-address-form .btn h3 {
    font-size: 1.1rem;
  }

  .cartitem .head h2 {
    font-size: 1.4rem;
  }

  .cartitem .delivery-location {
    padding: 5px;
  }

  .merge-data .merge-row .merge-text {
    display: none;
  }

  .merge-data .merge-row .merge-text-b {
    width: 100%;
  }

  .profile-main {
    width: 95%;
    margin: 10px auto;
    padding: 15px 15px;
  }

  .book-details-container {
    display: block;
  }

  .book-details-container .main-image img {
    width: 70%;
    height: 380px;
  }

  .book-details-container .book-image-section {
    width: 100%;
  }

  .book-details-container .title {
    font-size: 1.1em;
    font-weight: bold;
  }

  .myorder-list .address-header {
    padding: 0px 10px;
    border-radius: 15px 15px 0px 0px;
    margin-bottom: 10px;
  }

  .myorder-list {
    padding: 10px 10px;
  }

  .book-details-container .product-details-section {
    width: 100%;
  }

  .modal-overlay-c .confirmation-box {
    max-width: 350px;
  }

  .myorder-list .address-header .title {
    font-size: 20px
  }

  .my-address .address-header {
    padding: 0px 10px;
    border-radius: 15px 15px 0px 0px;
    margin-bottom: 10px;
    margin: 10px 10px;
  }

  .my-address .title {
    font-size: 20px;
  }

  .profile-main .profile-form {
    margin-top: 15px;
  }

  .account-order-detail .header {
    padding: 0px 10px;
    border-radius: 15px 15px 0px 0px;
  }

  .account-order-detail .title {
    font-size: 20px;
  }

  .change-password-container {
    display: block;
  }

  .change-password-container .form {
    width: 100%;
  }

  .change-password-container .password-requirements {
    width: 100%;
  }

  .change-password-container h2 {
    font-size: 23px;
  }

  .change-password-container label {
    font-size: 16px;
  }

  .change-password-container h4 {
    font-size: 1.1rem;
  }

  .change-password-container li {
    font-size: 14px;
  }

  .my-address .address-list {
    display: block;
    padding: 0px 10px;
    margin-bottom: 10px;

  }

  .account-order-detail {
    width: 100%;
    padding: 10px 10px;
  }

  .my-address .address-card {
    margin-top: 10px;
  }

  .featuredbrand .text h2 {
    font-size: 1.8rem;
  }

  .cart-item-add-more .sec-btn h4 {
    font-size: .9rem;
  }

  .cart-item-add-more .sec-btn button {
    font-size: .8rem;
  }

  .promo .promo-drawer {
    width: 100%;
  }

  /* -----filter-category---- */
  .main-sort .text-book h2 {
    font-size: 1.4rem;
    color: black;
    font-family: Arial, sans-serif;
  }

  .main-sort .text-book .price-select select {
    margin-bottom: 0;
    width: 52%;
  }

  .springboard-notes .book-card {
    width: 48%;
    height: 332px;
  }

  .springboard-notes .book-card .h3 {
    height: 60px;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 346px !important;
  }

  .springboard-notes .books-container {
    gap: 24px;
    padding: 27px 0px 0px 20px;
  }

  .springboard-notes .price-section button {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .price-section .add-btn {
    width: 62px;
    height: 28px;
  }

  .springboard-notes .left-book .flex .img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .springboard-notes .left-book .flex .img-a {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .text {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .img {
    border-radius: 14%;
    height: 60px;
    width: 75%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .springboard-notes .left-book .flex h3 {
    margin-left: 0px;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: center;
    color: #373737;
  }

  .springboard-notes .left-book .flex-2 {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 10px;
  }

  .springboard-notes .left-book {
    width: 22%;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .springboard-notes .books-container {
    gap: 10px;
    padding: 10px 0px 6px 6px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 15px;
  }

  .main-sort .text-book {
    padding: 10px 15px 10px 15px;
  }

  .springboard-notes .book-image a {
    height: 212px;
  }

  .springboard-notes .book-image .p {
    height: 212px;
  }

  .slick-next {
    display: none !important;
  }

  .slick-prev {
    display: none !important;
  }

  .display-none {
    display: none;
  }

  .display-none-2 {
    display: block !important;
  }

  .slider-container::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
  }

  .slider-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }

  /* Show scrollbar when active */
  .slider-container.scroll-active::-webkit-scrollbar-thumb {
    display: block;
  }

  .slider-container {
    display: flex;
    align-items: start;
    background-color: white;
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-height: 395px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: 5px;
    flex-direction: column;
  }

  .slider-container::-webkit-scrollbar {
    display: none;
  }

  .featuredbrand .book {
    width: 52% !important;
  }

  .featuredbrand .book {
    padding: 0px !important;
    margin: 15px 5px;
  }

  .springboard-notes .book-card .p {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
  }

  /* -------login-otp---- */

  .otp-modal .otp strong {
    font-size: 13px;
  }

  .otp-modal .otp {
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .otp-modal .otp-input-box {
    width: 35px;
    height: 33px;
  }

  .filter-category .filter .nav-item .img {
    width: 55px;
    height: 55px;
  }

  .filter-category .filter .nav-item .text-container {
    font-size: 14px;
  }

  .main-address {
    margin: 10px 16px;
  }

  .address-detail-form .address-box-text {
    padding: 0px 10px;
    background-color: white;
    border-radius: 7px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 490px) {
  .myorder-list .order-header {
    display: flex;
    justify-content: end;
    align-items: center;
  }

  .aboutus-container .hero-section h1 {
    font-size: 2rem;
  }

  .aboutus-container .hero-section p {
    font-size: 15px;
  }

  .aboutus-container .hero-section {
    padding: 10px 10px;
  }

  .page-heading .page-head {
    font-size: 12px;
  }

  .white-screen {
    margin-top: 50%;
  }

  .animated-text {
    font-size: 22px;
    color: #666;
    animation: pulse 1.5s ease-in-out infinite;
    text-align: center;
  }

  .my-address .delete-button {
    background-color: #f43f5e;
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 17px;
    -webkit-tap-highlight-color: transparent;
  }

  .my-address .edit-button {
    font-size: 17px;
    -webkit-tap-highlight-color: transparent;
    background-color: #103E8A;
    color: white;
    padding: 0px 8px;
    border-radius: 5px;
  }

  .address-detail-form-b .form-group-2 {
    display: block;
  }

  .address-detail-form .form-group-2 {
    display: block;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .swiper {
    width: 100%;
    height: 200px;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 200px;
  }

  .footer-container .footer-social-media a {
    margin: 0px 7px;
    padding: 0px 2px
  }

  .featuredbrand .book .book-btn .price-b {
    font-family: Arial, sans-serif;
    font-size: 16px;
  }

  .featuredbrand .book .book-btn .original-price {
    font-size: 17px;
  }

  .footer-container .footer-column h3 {
    font-size: 1.4rem;
  }

  .footer-container .footer-logo {
    width: 66%;
  }

  .otp-modal .otp {
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }

  .footer-container .copy {
    font-size: 1rem;
  }

  .res-navbar {
    padding: 5px 15px;
  }

  .res-main .dropdown-menu {
    width: 60%;
    left: -35px;
  }

  .res-navbar .logo .img {
    width: 65%;
  }

  .sort-2 .text-book h2 {
    font-size: 1.3rem;
  }

  .main-sort .text-book .price-select select {
    margin-bottom: 0;
    width: 65%;
  }

  .featuredbrand .book-img .p {
    height: 190px;
  }

  .featuredbrand .book {
    height: 320px;
    margin: 12px 5px;
  }

  .featuredbrand .book {
    padding: 0px !important;
  }

  .featuredbrand .book {
    width: 52% !important;
  }

  .profile-main {
    width: 95%;
  }

  /* --cart--- */
  .cart-container {
    width: 100%;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 150px;
    z-index: 100;
  }

  .total-amount .proceed-button {
    padding: 15px 10px;
    font-size: 16px;
  }

  .bill-details .bill-row span {
    font-size: .9rem;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .new-address-form {
    width: 100%;
    height: 100vh;
  }

  .total-amount-cart .proceed-button-cart {
    padding: 10px 10px;
  }

  .cartitem {
    width: 60%;
    height: 100vh;
  }

  .remove-btn button {
    width: 26%;
  }

  .profile-form .form-submit button {
    width: 30%;
  }

  .profile-form .form-field label {
    font-weight: 500;
    font-size: 1em;
  }

  .profile-form .form-field input {
    padding: 12px 8px;
    font-size: 1rem;
  }

  .profile-main {
    padding: 10px 10px 48px 13px;
  }

  .profile-main .btn button {
    padding: 7px 20px;
  }

  .profile-main .form-submit button {
    padding: 9px 25px;
    font-size: 1rem;
  }

  .book-details-container .txt .txt-b {
    display: block;
  }

  .book-details-container .price-info {
    margin-left: 0px;
  }

  .book-details-container .title {
    font-size: 1em;
  }

  .book-details-container .main-image img {
    width: 100%;
    height: 350px;
  }

  .profile-form .form-row {
    display: block;
  }

  .profile-form .form-field {
    width: 100%;
  }

  .profile-form .user-form {
    height: 320px;
  }

  .profile-form .form-container {
    height: 445px;
  }

  .footer-container .footer-column .footer-p {
    font-size: .95rem;
  }

  .featuredbrand .text h2 {
    font-size: 1.7rem;
  }

  .promo-code .promo-message {
    font-size: 15px;
  }

  .promo-code .promo-status {
    font-size: 15px;
  }

  .cart-item .cart-item-image img {
    height: 63px;
  }

  .cart-item .cart-item-image {
    width: 16%;
  }

  /* --------filter-category----- */

  .main-sort .text-book h2 {
    font-size: 1.3rem;
  }

  .main-sort .text-book .price-select {
    width: 55%;
  }

  .springboard-notes .book-card {
    width: 48%;
    height: 305px;
  }

  .springboard-notes .books-container {
    gap: 24px;
    padding: 27px 0px 0px 20px;
  }

  .springboard-notes .price-section button {
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .springboard-notes .price-section .add-btn {
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .springboard-notes .left-book .flex .img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .springboard-notes .left-book .flex .img-a {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .text {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .img {
    border-radius: 14%;
    height: 60px;
    width: 90%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .springboard-notes .left-book .flex h3 {
    margin-left: 0px;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: #373737;
  }

  .springboard-notes .left-book .flex-2 {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 3px;
  }

  .springboard-notes .left-book {
    width: 24%;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .springboard-notes .books-container {
    gap: 10px;
    padding: 14px 0px 6px 6px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 15px;
  }

  .springboard-notes .book-image a {
    height: 160px;
  }

  .springboard-notes .book-image .p {
    height: 160px;
  }

  .springboard-notes .book-card .h3 {
    height: 54px !important;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 322px !important;
  }

  .springboard-notes .book-card h3 {
    font-size: 15px;
    height: 37px;
  }

  .springboard-notes .price .mrp-price {
    font-size: 15px;
  }


  .springboard-notes .original-price {
    color: gray;
    font-family: Arial, sans-serif;
    font-size: 15px;
  }

  .springboard-notes .price-section {
    display: block;
  }

  .springboard-notes .price {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .featuredbrand .book .book-btn p {
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .merge-data .merge-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #f1f7f7;
  }

}

@media screen and (max-width: 460px) {
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 145px;
    z-index: 100;
  }
}

@media screen and (max-width: 440px) {
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .springboard-notes .book-image a {
    height: 152px;
  }

  .filter-category .filter .nav-item .filter-link {
    height: 95px;
  }

  .featuredbrand .book-img .p {
    height: 180px;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 135px;
    z-index: 100;
  }

  .filter-category .filter .nav-item .img {
    width: 55px;
    height: 55px;
  }

  .filter-category .filter .nav-item .text-container {
    font-size: 13px;
  }

}

@media (min-width: 390px) and (max-width: 430px) {
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 170px;
  }

  .featuredbrand .book-img .p {
    height: 180px;
  }

  .featuredbrand .book {
    height: 295px;
  }

  .profile-main {
    padding: 14px 14px 50px 14px;
  }

  .springboard-notes .book-card {
    width: 48%;
    height: 300px;
  }
}


@media screen and (max-width: 390px) {
  .animated-text {
    font-size: 19px;
    color: #666;
    animation: pulse 1.5s ease-in-out infinite;
    text-align: center;
  }

  .modal-content-new {
    width: 330px !important;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .featuredbrand .book-img .p {
    height: 160px;
  }

  .book-details-container .title {
    font-size: .9em;
  }

  .swiper {
    width: 100%;
    height: 180px;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 135px;
    z-index: 100;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 160px;
  }

  .footer-container .footer-column {
    width: 75%;
    margin-top: 10px;
  }

  .footer-container {
    padding: 0;
  }

  .slick-next {
    right: 5px;
    color: black;
  }

  .slick-prev {
    left: 5px;
    color: black;
  }

  .featuredbrand .book .shipping {
    font-size: 17px;
    height: 41px;
  }

  .footer-container .copy {
    font-size: .8rem;
  }

  .res-navbar {
    padding: 5px 15px;
  }

  .res-main .dropdown-menu ul li {
    padding: 10px 0px 10px;
    font-size: 1.2rem;
  }

  .res-navbar .logo .img {
    width: 75%;
  }

  .cart-container {
    width: 100%;
  }

  .filter .nav-item .text-container {
    font-size: .7rem;
    width: 100%;
    margin: 3px;
  }

  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal .text-cart h2 {
    font-size: 1.3rem;
  }

  .login-modal .login-input {
    padding: 10px;
  }

  .new-address-form {
    width: 100%;
    height: 100vh;
  }

  .new-address-form .inner-text p {
    color: grey;
    font-size: .7rem;
  }

  .cartitem {
    width: 65%;
    height: 100vh;
  }

  .cart-item-add .cart-item-name {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: .9rem;
  }

  .cart-item-add .cart-item-description {
    font-size: 11px;
    width: 95%;
  }

  .cart-item-add .discounted-price {
    font-size: 14px;
  }

  .cart-item-add {
    display: block;
  }

  .new-address-form .form-lite {
    padding: 5px
  }

  .remove-btn button {
    width: 28%;
    height: 30px;
  }


  .book-details-container .main-image img {
    width: 100%;
    height: 330px;
  }

  .change-password-container h2 {
    font-size: 21px;
  }

  .change-password-container label {
    font-size: 14px;
  }

  .change-password-container h4 {
    font-size: 1rem;
  }

  .change-password-container li {
    font-size: 13px;
  }

  .featuredbrand .text h2 {
    font-size: 1.6rem;
  }

  .featuredbrand .book .book-btn {
    display: block;
  }

  .featuredbrand .book .book-btn button {
    width: 100%;
    height: 38px;
    font-size: .8rem;
    margin-top: 5px;
  }

  .featuredbrand .book .book-btn .add-btn {
    width: 100%;
    height: 38px;
    font-size: .8rem;
    margin-top: 5px;
    display: flex;
    justify-content: space-evenly;
  }

  .footer-container .footer-logo {
    width: 42%;
  }

  .footer-container .footer-column {
    width: 100%;
  }

  .promo-code .promo-message {
    font-size: 14px;
  }

  .promo-code .promo-status {
    font-size: 14px;
    color: #103E8A;
  }

  .cart-item .cart-item-image {
    width: 17%;
  }

  /* -----filter-category----- */

  .sort-2 .text-book h2 {
    font-size: 1.3rem;
  }

  .springboard-notes .book-card .h3 {
    height: 54px !important;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  .springboard-notes .book-card-res {
    height: 290px !important;
  }

  .springboard-notes .book-card {
    width: 47%;
    height: 271px;
  }

  .springboard-notes .books-container {
    gap: 24px;
    padding: 27px 0px 0px 20px;
  }

  .springboard-notes .price-section button {
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .springboard-notes .price-section .add-btn {
    width: 100%;
    height: 35px;
    margin-top: 5px;
  }

  .springboard-notes .left-book .flex .img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  .springboard-notes .left-book .flex .img-a {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .text {
    display: flex;
    justify-content: center;
  }

  .springboard-notes .left-book .flex .img {
    border-radius: 14%;
    height: 53px;
    width: 80%;
    overflow: hidden;
    margin-bottom: 5px;
  }

  .springboard-notes .left-book .flex h3 {
    margin-left: 0px;
    font-weight: 500;
    font-size: 12px;
    width: 100%;
    text-align: center;
    color: #373737;
  }

  .springboard-notes .left-book .flex-2 {
    flex-wrap: wrap;
    justify-content: center !important;
    align-items: center !important;
    padding: 8px 3px;
  }

  .springboard-notes .left-book {
    width: 27%;
    flex-direction: column;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .springboard-notes .books-container {
    gap: 10px;
    padding: 15px 0px 6px 6px;
    overflow-y: scroll;
    scrollbar-width: none;
    padding-bottom: 15px;
  }

  .springboard-notes .book-image a {
    height: 140px;
  }

  .springboard-notes .book-image .p {
    height: 140px;
    font-size: 14px;
  }

  .springboard-notes .book-card h3 {
    font-size: 15px;
    padding: 0px 10px;
    margin-top: 8px;
    height: 37px;
    margin-bottom: 7px;
  }

  .springboard-notes .price .mrp-price {
    font-size: 15px;
  }


  .springboard-notes .original-price {
    color: gray;
    font-family: Arial, sans-serif;
    font-size: 15px;
  }

  .springboard-notes .price-section {
    display: block;
  }

  .springboard-notes .price {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .featuredbrand .book {
    height: 308px;
    padding: 0px !important;
    margin: 9px 5px;
  }

  .featuredbrand .book {
    width: 52% !important;
  }

  .merge-data .merge-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #f1f7f7;
  }
}

@media screen and (max-width: 340px) {
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 125px;
    z-index: 100;
  }

  .modal-content-new {
    width: 295px !important;
  }
}

@media screen and (max-width: 330px) {
  .login-modal {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
  }

  .login-modal-number {
    height: 100%;
    width: 100%;
  }

  .featuredbrand .book-img .p {
    height: 130px;
  }

  .sort-2 {
    display: block !important;
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    z-index: 100;
  }

  .main-sort .text-book h2 {
    font-size: 1.2rem;
  }

  .footer-container .copy {
    font-size: .7rem;
  }

  .res-main .dropdown-menu {
    width: 64%;
  }

  .res-main .dropdown-menu ul li {
    padding: 10px 0px 10px;
    font-size: 1.3rem;
  }

  /* --cart--- */
  .cart-container {
    width: 100%;
  }

  .filter .nav-item .text-container {
    font-size: .6rem;
    width: 100%;
    margin: 3px;
  }

  .featuredbrand .book-img img {
    width: 100%;
    height: 130px;
  }

  .new-address-form {
    width: 100%;
    height: 100vh;
  }

  .new-address-form .form-lite {
    padding: 5px 10px;
  }

  .new-address-form .inner-text h3 {
    font-size: 1.1rem;
  }

  .cartitem {
    width: 74%;
    height: 100vh;
  }

  .cartitem .delivery-location {
    padding: 2px;
  }

  .cartitem .delivery-location .location h3 {
    font-size: 1.5rem;
  }

  .cartitem .location-details p {
    margin: 4px;
    font-size: 15px;
  }

  .total-amount-cart .proceed-button-cart {
    padding: 5px 10px;
  }

  .featuredbrand .book .book-btn button {
    width: 100%;
    height: 30px;
    font-size: .8rem;
  }

  .featuredbrand .book .book-btn .add-btn {
    width: 100%;
    height: 30px;
  }

  .featuredbrand .book .book-btn .add-btn span {
    font-size: 1.3rem;
  }

  .remove-btn button {
    width: 29%;
    height: 31px;
  }

  .book-details-container .main-image img {
    width: 92%;
    height: 300px;
  }

  .login-modal .login-button {
    width: 70%;
  }

  .cart-item .cart-item-image {
    width: 18%;
  }

  .featuredbrand .book {
    height: 270px;
  }
}

.dropdown-menu-b {
  display: none;
}

/* modal css */

.modal-overlay-c {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay-c p {
  display: flex;
  justify-content: end;
  margin-bottom: 5px;
  font-size: 1rem;
}

.modal-content-c {
  background: white;
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content-c input {
  width: 100%;
  padding: 6px 10px;
  margin-top: 10px;
  font-size: 1.1rem;
  border-radius: 5px;
  border: 1px gray solid;
}

.modal-actions-c {
  margin-top: 20px;
}

.confirm-btn-c,
.cancel-btn-c {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}

.confirm-btn-c {
  background-color: #f44336;
  color: white;
}

.cancel-btn-c {
  background-color: #103E8A;
  color: white;
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  margin-top: 4px;
}

.filter-category-skeleton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeholder;
  animation-timing-function: linear;
  background: #eeeeee;
  background: linear-gradient(to right, #eee 10%, #ddd 18%, #eee 40%);
  background-size: 1200px 100px;
  border-radius: 3px;

}

@keyframes placeholder {
  0% {
    background-position: -400px 0;
  }

  100% {
    background-position: 400px 0;
  }
}

.featured-skeleton-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skeleton-container {
  padding: 20px;
}

.skeleton-text-book {
  width: 50%;
  height: 30px;
  background: #e0e0e0;
  margin-bottom: 20px;
  border-radius: 4px;
}

.skeleton-left-book .skeleton-category {
  width: 100%;
  height: 50px;
  background: #e0e0e0;
  margin-bottom: 10px;
  border-radius: 4px;
}

.skeleton-books-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.skeleton-book-card {
  width: calc(33.33% - 20px);
  background: #f6f6f6;
  padding: 15px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-image {
  width: 100%;
  height: 200px;
  background: #e0e0e0;
  border-radius: 4px;
}

.skeleton-title,
.skeleton-price {
  width: 80%;
  height: 20px;
  background: #e0e0e0;
  margin: 10px 0;
  border-radius: 4px;
}

.category-skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
  justify-content: center;
}

.category-skeleton-item {
  background-color: #e0e0e0;
  /* Light gray background */
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.category-skeleton-item::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(224, 224, 224, 0.6) 50%, rgba(224, 224, 224, 0) 100%);
  animation: shimmer 1.5s infinite;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.logo-loading {
  width: 204px;
  height: 204px;
  border: 5px solid #ddd;
  border-top: 5px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.logo-loading-small {
  width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  border-top: 1px solid #007bff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 1.5px;
}

.modal-overlay-new {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-content-new {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  width: 400px;
  text-align: center;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
}

.close-btn-new {
  position: absolute;
  top: 16px;
  right: 16px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #555;
}

.modal-title-new {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 12px;
}

.modal-description-new {
  font-size: 14px;
  color: #666666;
  margin-bottom: 16px;
}

.modal-input-new {
  width: 100%;
  padding: 12px;
  border: 1px solid #cccccc;
  border-radius: 8px;
  font-size: 14px;
  margin-bottom: 16px;
  outline: none;
  transition: border-color 0.3s ease;
}

.modal-input-new:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.modal-actions-new {
  display: flex;
  justify-content: center;
}

.submit-btn-new {
  background: #103E8A;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.submit-btn-new:hover {
  background: #0056b3;
}

.submit-btn-new:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.form-text .delivery-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 8px;
  background-color: white;
}

.form-text .delivery-type:hover {
  cursor: pointer;
}

.form-text .delivery-type .delivery-type-text {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-text .delivery-type .delivery-type-text p {
  display: flex;
  align-items: center;
}

.form-text .delivery-type .delivery-type-text p span {
  color: green;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  margin-left: 5px;
}

.form-text .delivery-type .order-total-amount {
  border-right: .5px dashed grey;
}

.form-text .delivery-type .order-total-amount h3 {
  padding: 6px;
  font-weight: 500;
  color: #000;
}

.form-text .delivery-type .delivery-type-text .pay-radio input {
  width: 17px;
  height: 17px;
  accent-color: #103e8a;
}

.position-class {
  position: relative;
}

.position-class .bottom-button-area {
  position: absolute;
  bottom: 0px;
  width: 100%;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}
.position-class .bottom-button-area .order-total-amount button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 100%;
  padding: 18px 10px;
  background-color: #103E8A;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
}

.position-class .bottom-button-area .order-total-amount .ordertype-amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-class .bottom-button-area .order-total-amount .ordertype-amount span {
  margin-left: 5px;
}

.new-address-form .delivery-speed {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ddd;
  margin: 8px;
  background-color: white;
}